import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal2Button from "../../common/Modal2Button";
import Joi from "joi-browser";

class CategoryCreate extends BaseModal {
  state = {
    data: {
      name: "",
      description: "",
    },
    errors: {},
  };

  schema = {
    name: Joi.string().required().label("Catagory Title"),
    description: Joi.string().required().label("Description"),
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Add Category", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  renderBody() {
    return (
      <div>
        <div className="row">
        <div className="col-12">
        <div className="col-12" style={{ marginTop: "80px" }}>
          {this.renderInput("name", "Category Title")}
        </div>
         <div className="col-6" style={{ marginTop: "10px" }}>
          {this.renderTextArea("description", "Description")}
         </div>
        </div>
        <div className="col-3 img-center">
           
          </div>
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
        <Modal2Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
          />
        </div>
        </div>
    );
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    const { onPositive } = this.props;
    let data = { ...this.state.data };
    this.clearData();
    onPositive(data);
  };

  clearData() {
    this.setState({
      data: {
        name: "",
        description: "",
      },
    });
  }
}

export default CategoryCreate;
