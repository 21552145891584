import React from "react";

function ModalInput(props) {
  const { title, icon, name, error, defaultValue, ...rest } = props;
  return (
    <React.Fragment>
      <div className="form-group">
        <label className="modal-input-title">{title}</label>
        <div>
          <input
            {...rest}
            name={name}
            className="modal-input-in-div"
            id={name}
          />
        </div>
        {error && (
          <div className="input-error ">
            <label>{error}</label>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ModalInput;
