import http from "./HttpService";
import { authUrl } from "../config.json";
import jwtDecode from "jwt-decode";
import _ from "lodash";
const apiEndpoint = `${authUrl}`;
const tokenKey = "token";

//http.setJwt(getJwt());

export async function login(username, password, isRememberMe) {
  const { data: jwt } = await http.post(apiEndpoint, {
    username,
    password,
  });
  localStorage.setItem(tokenKey, JSON.stringify(jwt));
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  localStorage.removeItem(tokenKey);
}

export async function authHeader() {
  const currentUser = getCurrentUser();
  if (currentUser && currentUser.token) {
    return { Authorization: `Bearer ${currentUser.token}` };
  } else {
    return {};
  }
}

export function getCurrentUser() {
  try {
    return jwtDecode(getJwt());
  } catch (error) {
    return null;
  }
}

export function getCurrentUserRole() {
  const currentUser = getCurrentUser();
  let role = _.findKey(currentUser, function (value, key) {
    console.log(key.includes("claims/role") + "  " + key + "  " + value);
    if (key.includes("claims/role")) return key;
  });
  return currentUser[role];
}
export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getCurrentUserRole,
  getJwt,
};
