import React from "react";
import BaseModal from "../../common/BaseModal";
import Music from "../../common/Music";
import MusicDownload from "../../common/MusicDownload";
import Modal3Button from "../../common/Modal3Button";
import InputFileButtonPrimary from "../../common/InputFileButtonPrimary";
import Progressbar from "../../common/Progressbar";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import NoImageIcon from "../../../images/no_image_rounded.svg";

class StoryPageEdit extends BaseModal {
  imageNotSelectMessage = "Please upload an image for the page";
  state = {
    data: {
      id: "",
      storyId: "",
      number: 1,
      sekaniStory: "",
      englishStory: "",
      image: null,
      englishAudio: null,
      sekaniAudio: null,
      rowVersion: "",
    },
    englishAudio: null,
    sekaniAudio: null,
    errors: {},
    progressbarIsShow: false,
  };

  componentDidMount = async () => {
    const { data } = this.props;
    if (data) {
      if (data != null) {
        this.setState({
          data: data,
        });
      }
      let image = await fetch(data.imageUrl).then((r) => r.blob());
      data.image = image;

      let sekaniAudio = await fetch(data.sekaniAudioUrl).then((r) => r.blob());
      data.sekaniAudio = sekaniAudio;

      let englishAudio = await fetch(data.englishAudioUrl).then((r) =>
        r.blob()
      );
      data.englishAudio = englishAudio;

      this.setState({
        data: data,
        image: URL.createObjectURL(image),
        englishAudio: URL.createObjectURL(englishAudio),
        sekaniAudio: URL.createObjectURL(sekaniAudio),
      });
    }
  };

  schema = {
    id: Joi.any(),
    rowVersion: Joi.any(),
    storyId: Joi.any(),
    number: Joi.number().required().label("Number of page"),
    sekaniStory: Joi.string().required().label("Sekani Story"),
    englishStory: Joi.string().required().label("English Story"),
    englishAudioUrl: Joi.any(),
    sekaniAudioUrl: Joi.any(),
    imageUrl: Joi.any(),
    image: Joi.label("Page image"),
    englishAudio: Joi.any(),
    sekaniAudio: Joi.any(),
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Add page", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  onEnglishVoice = () => {};

  renderBody() {
    return (
      <div>
        <div className="row">
          <div className="col-7">
            <div className="col-12" style={{ marginTop: "10px" }}>
              {this.renderTextArea("sekaniStory", "Story Text Sekani")}
            </div>
            <div
              className="row"
              style={{ width: "fit-content", paddingLeft: "inherit" }}
            >
              <div style={{ margin: "auto" }}>
                <InputFileButtonPrimary
                  accept="audio/*"
                  text="Upload Audio"
                  onChange={this.handelSekaniChooseFile}
                />
              </div>

              {this.state.data.sekaniAudio ? (
                <Music
                  isEnable={this.state.data.sekaniAudio === null}
                  audio={this.state.data.sekaniAudio}
                />
              ) : (
                this.state.data.sekaniAudioUrl && (
                  <MusicDownload url={this.state.data.sekaniAudioUrl} />
                )
              )}
            </div>
            <div className="col-12" style={{ marginTop: "10px" }}>
              {this.renderTextArea("englishStory", "Story Text English")}
            </div>

            <div
              className="row"
              style={{ width: "fit-content", paddingLeft: "inherit" }}
            >
              <div style={{ margin: "auto" }}>
                <InputFileButtonPrimary
                  accept="audio/*"
                  text="Upload Audio"
                  onChange={this.handelEnglishChooseFile}
                />
              </div>

              {this.state.data.englishAudio ? (
                <Music
                  isEnable={this.state.data.englishAudio === null}
                  audio={this.state.data.englishAudio}
                />
              ) : (
                this.state.data.englishAudioUrl && (
                  <MusicDownload url={this.state.data.englishAudioUrl} />
                )
              )}
            </div>
          </div>
          <div className="col-5 img-center">
            {this.renderImageRounded(
              "image",
              this.state.image,
              NoImageIcon,
              this.imageNotSelectMessage
            )}
          </div>
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal3Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
            onChooseFile={this.handelChooseFile}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };
    if (data.image === null) {
      this.toastImageMessage();

      return;
    }
    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  toastImageMessage() {
    toast.error(this.imageNotSelectMessage, {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelEnglishChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        englishAudio: event.target.files[0],
      },
      englishAudio: URL.createObjectURL(event.target.files[0]),
    });
  };

  handelSekaniChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        sekaniAudio: event.target.files[0],
      },
      sekaniAudio: URL.createObjectURL(event.target.files[0]),
    });
  };

  handelChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        image: event.target.files[0],
      },
      image: URL.createObjectURL(event.target.files[0]),
    });
  };

  clearData = async () => {
    this.setState({
      data: {
        name: "",
        number: 0,
        description: "",
        sekaniAudio: null,
        englishAudio: null,
      },
      sekaniAudio: null,
      englishAudio: null,
    });
  };
}

export default StoryPageEdit;
