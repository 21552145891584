import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal2Button from "../../common/Modal2Button";
import Joi from "joi-browser";
import Progressbar from "../../common/Progressbar";
import api from "../topic/TopicService";
import { AsyncPaginate } from "react-select-async-paginate";
import ItemDeleteable from "../../common/ItemDeleteable";
import _ from "lodash";
import axios from "axios";
class TopicSetter extends BaseModal {
  state = {
    data: {
      Topics: [],
    },
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    Topics: Joi.array().items(Joi.object().required()),
  };

  token = null;
  componentDidMount() {
    const { data } = this.props;
    const Topics = data.topics;
    const CancelToken = axios.CancelToken;
    this.token = CancelToken.source();
    this.setState({ data: { Topics } });
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Set Topics", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseJSON = await api.GetList(searchQuery, page, "", this.token);

    const result = {
      options: responseJSON.data,
      hasMore: responseJSON.data.length >= 1,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
    return result;
  };

  onChange = (option) => {
    var topic = { id: option.id, name: option.name };
    var found = _.find(this.state.data.Topics, function (n) {
      return n.id === topic.id;
    });
    if (!found) {
      var Topics = [...this.state.data.Topics, topic];
      this.setState({ data: { Topics } });
    }
  };

  onTopicDelete = (topic) => {
    var Topics = this.state.data.Topics;
    _.remove(Topics, function (n) {
      return n.id === topic.id;
    });

    this.setState({ data: { Topics } });
  };

  renderTopic(topic) {
    return (
      <ItemDeleteable
        title={topic.name}
        onRemove={() => this.onTopicDelete(topic)}
      />
    );
  }

  renderBody() {
    const { Topics } = this.state.data;
    return (
      <div>
        <div className="row">
          <div className="col-5" style={{ marginTop: "30px" }}>
            <AsyncPaginate
              key="Selector"
              loadOptions={this.loadOptions}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={this.onChange}
              isSearchable={true}
              placeholder="Select Topic"
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className="col-6" style={{ marginTop: "30px", height: "250px" }}>
            <div className="row row-cols-auto">
              {Topics && Topics.map((topic) => this.renderTopic(topic))}
            </div>
          </div>
        </div>
        <div className="" style={{ marginTop: "30px", marginBottom: "10px" }}>
          <Modal2Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    await this.setProgressbarVisibility(true);
    const { onPositive, data } = this.props;
    let Topics = [];
    _.forEach(this.state.data.Topics, function (topic) {
      Topics = [...Topics, topic.id];
    });
    let dataForSave = { Topics, RootId: data.id };
    await this.clearData();
    await onPositive(dataForSave);
    await this.setProgressbarVisibility(false);
  };

  clearData = async () => {
    this.setState({
      data: {
        Topics: [],
      },
    });
  };
}

export default TopicSetter;
