import http from "./HttpService";
import { apiUrl } from "../config.json";
export default {
  GetAll(apiEndpoint, order, pageCount = 99999) {
    var orderString =
      order === undefined || order === ""
        ? ""
        : `&order=${order.path} ${order.order}`;
    var pageCount = `pageCount=${pageCount}`;
    if (apiEndpoint.includes("?")) {
      pageCount = `&${pageCount}`;
    } else {
      pageCount = `?${pageCount}`;
    }
    return http.get(`${apiUrl}${apiEndpoint}${orderString}`);
  },

  GetAllQueryString(apiEndpoint, query = "", order, pageCount = 99999) {
    var orderString =
      order === undefined || order === ""
        ? ""
        : `&order=${order.path} ${order.order}`;
    var pageCount = `pageCount=${pageCount}`;
    if (query.includes("?")) {
      pageCount = `&${pageCount}`;
    } else {
      pageCount = `?${pageCount}`;
    }
    return http.get(
      `${apiUrl}${apiEndpoint}${query}${pageCount}${orderString}`
    );
  },

  GetOne(apiEndpoint, queryString, order) {
    var orderString =
      order === undefined || order === ""
        ? ""
        : `&order=${order.path} ${order.order}`;
    return http.get(`${apiUrl}${apiEndpoint}?${queryString}&${orderString}`);
  },

  Get(
    apiEndpoint,
    SearchText,
    SkipPages,
    order,
    pageCount1 = 10,
    cancelToken,
    config
  ) {
    var orderString =
      order === undefined || order === ""
        ? ""
        : `&order=${order.path} ${order.order}`;

    let queryString =
      SearchText === ""
        ? `${orderString}`
        : `&SearchText=${SearchText}&${orderString}`;
    queryString =
      SkipPages === 0
        ? queryString
        : queryString + `&SkipPages=${SkipPages}&${orderString}`;

    var pageCount = `pageCount=${pageCount1}`;
    if (apiEndpoint.includes("?")) {
      pageCount = `&${pageCount}`;
    } else {
      pageCount = `?${pageCount}`;
    }

    return http.get(
      `${apiUrl}${apiEndpoint}${pageCount}${queryString}`,
      config,
      {
        cancelToken: cancelToken.token,
      }
    );
  },

  DeleteCustom(apiEndpoint) {
    return http.delete(`${apiUrl}${apiEndpoint}`);
  },

  Delete(apiEndpoint, id) {
    return http.delete(`${apiUrl}${apiEndpoint}/${id}`);
  },

  CreateMultipart(apiEndpoint, obj, config) {
    return http.post(`${apiUrl}${apiEndpoint}`, this.getFormDate(obj), config);
  },

  UpdateMultipart(apiEndpoint, obj, config) {
    return http.put(`${apiUrl}${apiEndpoint}`, this.getFormDate(obj), config);
  },

  Create(apiEndpoint, obj, config) {
    return http.post(`${apiUrl}${apiEndpoint}`, obj, config);
  },

  Update(apiEndpoint, obj, config) {
    return http.put(`${apiUrl}${apiEndpoint}`, obj, config);
  },

  getFormDate(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  },
};
