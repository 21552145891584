import React, { Component } from "react";
import ButtonPrimary from "../../common/ButtonPrimary";
import Grid from "../../common/Grid";
import ColCard from "./../../common/ColCard";
class StoryPageGrid extends Component {
  card = {
    content: (item) => (
      <ColCard
        style={{ hieght: "550px" }}
        title={"Page " + item.number}
        body={this.renderBody(item)}
        bottom={this.renderBottom(item)}
      />
    ),
  };

  renderBottom(item) {
    return (
      <div className="row card-item-bottom">
        <div style={{ margin: "auto" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div>
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      </div>
    );
  }
  renderBody(item) {
    return (
      <React.Fragment>
        <div style={{ width: "100%", height: 250 }} className="col img-center">
          <img
            style={{ width: "100%", height: 250 }}
            className="img-fluid "
            src={item.imageUrl}
            alt="Elder"
          />
        </div>
        <div className="col">
          <p
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: "190px",
            }}
          >
            {item.englishStory}
          </p>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { items, onFetchMore, isLoading, pageSize, skipPages } = this.props;
    return (
      <Grid
        style={{ margin: 20 }}
        data={items}
        card={this.card}
        skipPages={skipPages}
        pageSize={pageSize}
        onFetchMore={onFetchMore}
        isLoading={isLoading}
      />
    );
  }
}
export default StoryPageGrid;
