import React from "react";
const UserCard = ({ title, body, bottom, height = 400, width = 350 }) => {
  return (
    <div>
      <div className="card card-user" style={{ height: height, width: width }}>
        <div className="card-item-body" style={{ height: "80%" }}>
          {body}
        </div>
        <div className="center">{bottom}</div>
      </div>
    </div>
  );
};

export default UserCard;
