import React from "react";
import TableHeader from "../../components/common/TableHeader";
import TableBody from "../../components/common/TableBody";

const Table = (props) => {
  const {
    columns,
    sortColumn,
    onSort,
    data,
    rowClassName,
    onFetchMore,
    isLoading,skipPages,pageSize
  } = props;
  return (
    <div style={{ width: "94%", marginLeft: "2%" }}>
      <div>
        <div className="table-header-outer">
          <div className="table-header-inner">
            <TableHeader
              sortColumn={sortColumn}
              columns={columns}
              onSort={onSort}
            />
          </div>
        </div>

        <TableBody
          rowClassName={rowClassName}
          data={data}
          columns={columns}
          isLoading={isLoading}
          skipPages={skipPages}
          pageSize={pageSize}
          onFetchMore={onFetchMore}
        />
      </div>
    </div>
  );
};

export default Table;
