import React from "react";
const ColCard = ({ title, body, bottom, height = 550, width = 450 }) => {
  return (
    <div>
      <div className="card card-item" style={{ height: height, width: width }}>
        <div className="center">
          <div className="card-item-title center" style={{ marginTop: -12 }}>
            {title}
          </div>
        </div>
        <div className="card-item-body" style={{ height: "80%" }}>
          {body}
        </div>
        <div className="center">{bottom}</div>
      </div>
    </div>
  );
};

export default ColCard;
