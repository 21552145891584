import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal3Button from "../../common/Modal3Button";
import Progressbar from "../../common/Progressbar";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import NoImageIcon from "../../../images/no_image.svg";

class LevelEdit extends BaseModal {
  imageNotSelectMessage = "Please upload an image for the level";
  state = {
    data: {
      id: "",
      number: 0,
      name: "",
      description: "",
      image: null,
      rowVersion: "",
      imageUrl: "",
    },
    image: null,
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    id: Joi.any(),
    rowVersion: Joi.any(),
    imageUrl: Joi.any(),
    number: Joi.number().required().label("Level Number"),
    name: Joi.string().required().label("Level Name"),
    description: Joi.any(),
    image: Joi.any().label("Level Image"),
  };

  componentDidMount = async () => {
    const { data } = this.props;
    if (data) {
      if (data != null) {
        this.setState({
          data: data,
        });
      }
      let blob = await fetch(data.imageUrl).then((r) => r.blob());
      data.image = blob;
      this.setState({
        data: data,
        image: URL.createObjectURL(blob),
      });
    }
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Edit Level", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  renderBody() {
    return (
      <div>
        <div className="row">
          <div className="col-7">
            <div className="col-8" style={{ marginTop: "80px" }}>
              {this.renderInput("number", "Level Number", "number")}
            </div>
            <div className="col-8" style={{ marginTop: "10px" }}>
              {this.renderInput("name", "Name")}
            </div>
            <div className="col-12" style={{ marginTop: "10px" }}>
              {this.renderTextArea("description", "Description")}
            </div>
          </div>
          <div className="col-5 img-center">
            {this.renderImage(
              "image",
              this.state.image,
              NoImageIcon,
              "Please upload an image for the level"
            )}
          </div>
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal3Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
            onChooseFile={this.handelChooseFile}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };
    if (data.image === null) {
      this.toastImageMessage();

      return;
    }
    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  toastImageMessage() {
    toast.error("Please select a photo for level", {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        image: event.target.files[0],
      },
      image: URL.createObjectURL(event.target.files[0]),
    });
  };

  clearData = async () => {
    this.setState({
      data: {
        name: "",
        number: 0,
        description: "",
        image: null,
      },
      image: null,
    });
  };
}

export default LevelEdit;
