import React from "react";
const Image = ({ image, noImageIcon, width = 200, error, ...rest }) => {
  return (
    <React.Fragment>
      {image === null ? (
        <img
          {...rest}
          className="img-fluid"
          style={{ width: width }}
          src={noImageIcon}
        />
      ) : (
        <img
          {...rest}
          className="img-fluid"
          style={{ width: width }}
          src={image}
        />
      )}

      {image === null && error != null && (
        <div className="input-error ">
          <label>{error}</label>
        </div>
      )}
    </React.Fragment>
  );
};

export default Image;
