import React, { Component } from "react";
import Examples from "../Examples";
import RootBottomButtons from "../../../common/RootBottomButtons";
import WordInputCreate from "../../../common/WordInputCreate";
import WordInputEdit from "../../../common/WordInputEdit";
class InalienableNounItem extends Component {
  state = {};
  render() {
    const { item } = this.props;
    return (
      <React.Fragment>
        {item.persons.map((sub) => this.renderPerson(sub))}
        <div className="inalienable-row">
          <RootBottomButtons
            onSave={this.handleSave}
            onCancel={this.handleCancel}
            onNext={this.handelNext}
            onPrevious={this.handelPrevious}
            hasNext={item.hasNext}
            hasPrevious={item.hasPrevious}
            onSave={this.handelSave}
          />
        </div>
      </React.Fragment>
    );
  }
  handelNext = () => {
    const { item, onNext } = this.props;
    onNext(item);
  };
  handelPrevious = () => {
    const { item, onPrevious } = this.props;
    onPrevious(item);
  };

  handelSave = () => {
    const { item, onSave } = this.props;
    onSave(item);
  };

  handelChooseVoice = async (word, event) => {
    const { onChooseVoice } = this.props;
    var voice = event.target.files[0];
    onChooseVoice(word, voice, false);
  };

  handelDownloadVoice = async (word, file) => {
    const { onChooseVoice } = this.props;
    onChooseVoice(word, file, true);
  };

  handelAddExample = async (word, example) => {
    const { onAddExample } = this.props;
    onAddExample(word, example);
  };

  handelEditExample = async (word, example) => {
    const { onEditExample } = this.props;
    onEditExample(word, example);
  };

  handelDeleteExample = async (word, example) => {
    const { onDeleteExample } = this.props;
    onDeleteExample(word, example);
  };

  renderPerson(item) {
    let indexColorClasss = "dot-circle p" + item.index + "-color";
    return (
      <React.Fragment>
        <div className="border border-word row">
          <div className={indexColorClasss} />
          <div className="col">{item.text}</div>
        </div>
        {item.subItems.map((sub) => this.renderRow(sub))}
      </React.Fragment>
    );
  }
  renderRow(sub) {
    const { onWordChange } = this.props;
    return (
      <div className=" row inalienable-row" key={sub.item}>
        <div className="col  border">
          <WordInputCreate
            key={sub.attribute}
            word={sub}
            onChange={onWordChange}
            onChooseVoice={this.handelChooseVoice}
          />
          <div className="row">
            <div className="col-10 text-danger">
              {sub.error === "" ? "" : sub.error}
            </div>

            <div className="col">
              {sub.processIsShow ? (
                <div
                  className="spinner-border text-primary"
                  style={{ width: "1rem", height: "1rem" }}
                  role="status"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col border">
          <Examples
            word={sub}
            onExampleCreate={this.handelAddExample}
            onExampleEdit={this.handelEditExample}
            onExampleDelete={this.handelDeleteExample}
            examples={sub.wordExamples}
          />
        </div>
      </div>
    );
  }
}

export default InalienableNounItem;
