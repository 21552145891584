import BaseService from "../../../services/BaseService";
const endpoint = "/WordExampleAudio";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  Create(Id, audioFile) {
    let body = {
      Id: Id,
      audioFile: audioFile,
    };
    return BaseService.CreateMultipart(endpoint, body, multipartHeader);
  },
  Delete(id) {
    return BaseService.Delete(endpoint, id);
  },
};
