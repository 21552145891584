import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal3Button from "../../common/Modal3Button";
import Progressbar from "../../common/Progressbar";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import NoImageIcon from "../../../images/no_image.svg";

class UserCreate extends BaseModal {
  imageNotSelectMessage = "Please upload an image for the user";
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      image: null,
    },
    image: null,
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    firstName: Joi.string().required().label("First name"),
    lastName: Joi.string().required().label("Last name"),
    email: Joi.string().required().label("Email"),
    password: Joi.string().required().label("Password"),
    image: Joi.any().label("Level Image"),
  };

  render() {
    const { isOpen, onClose, type } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Add " + type, onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  renderBody() {
    return (
      <div>
        <div className="row">
          <div className="col-7">
            <div className="col-8" style={{ marginTop: "80px" }}>
              {this.renderInput("email", "Email")}
            </div>
            <div className="col-12" style={{ marginTop: "10px" }}>
              {this.renderInput("firstName", "First name")}
            </div>
            <div className="col-8" style={{ marginTop: "10px" }}>
              {this.renderInput("lastName", "Last name")}
            </div>
            <div className="col-8" style={{ marginTop: "10px" }}>
              {this.renderInput("password", "Password", "password")}
            </div>
          </div>
          {
            <div className="col-5 img-center">
              {this.renderImage("image", this.state.image, NoImageIcon)}
            </div>
          }
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal3Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
            onChooseFile={this.handelChooseFile}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };
    // if (data.image === null) {
    //   this.toastImageMessage();
    //   return;
    // }
    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  toastImageMessage() {
    toast.error("Please upload an image for the user", {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        image: event.target.files[0],
      },
      image: URL.createObjectURL(event.target.files[0]),
    });
  };

  clearData = async () => {
    this.setState({
      data: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        image: null,
      },
      image: null,
    });
  };
}

export default UserCreate;
