import React from "react";
const ButtonPrimary = ({ text, onClick, disabled, hidden, ...props }) => {
  return (
    <button
      {...props}
      hidden={hidden}
      disabled={disabled}
      className="button-primary"
      onClick={onClick}
    >
      <label style={{ width: "max-content" }} className="button-primary-text">
        {text}
      </label>
    </button>
  );
};

export default ButtonPrimary;
