import React from "react";
const InputFileButtonPrimary = ({
  text,
  onChange,
  disabled,
  accept,
  ...props
}) => {
  return (
    <button disabled={disabled} className="button-primary">
      <label className="button-primary-text">
        {text}
        <input
          type="file"
          accept={accept}
          onChange={onChange}
          style={{ display: "none" }}
        />
      </label>
    </button>
  );
};

export default InputFileButtonPrimary;
