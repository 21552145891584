const WordAttributes = {
  Generic: { id: 0, text: "Generic" },
  FirstPersonSingular: { id: 1, text: "First Person Singular" },
  FirstPersonPlural: { id: 2, text: "Second Person Singulr" },
  SecondPersonSingular: { id: 3, text: "Second Person Plural" },
  SecondPersonPlural: { id: 4, text: "Third Person Singular" },
  ThirdPersonSingular: { id: 5, text: "Third Person Plural" },
  ThirdPersonPlural: { id: 6, text: "Reflexive" },
  Reflexive: { id: 7, text: "Plural Reflexive" },
  PluralReflexive: { id: 8, text: "Disjoint Reference" },
  DisjointReference: { id: 9, text: "Plural Disjoint Reference" },
  PluralDisjointReference: { id: 10, text: "Reciprocal" },
  Reciprocal: { id: 11, text: "Indefinite" },
  Indefinite: { id: 12, text: "Imperfective First Person Singular" },
  ImperfectiveFirstPersonSingular: {
    id: 13,
    text: "Imperfective First Person Dual",
  },
  ImperfectiveFirstPersonDual: {
    id: 14,
    text: "Imperfective First Person Plural",
  },
  ImperfectiveFirstPersonPlural: {
    id: 15,
    text: "Imperfective Second Person Singular",
  },
  ImperfectiveSecondPersonSingular: {
    id: 16,
    text: "Imperfective Second Person Dual",
  },
  ImperfectiveSecondPersonDual: {
    id: 17,
    text: "Imperfective Second Person Plural",
  },
  ImperfectiveSecondPersonPlural: {
    id: 18,
    text: "Imperfective Third Person Singular",
  },
  ImperfectiveThirdPersonSingular: {
    id: 19,
    text: "Imperfective Third Person Dual",
  },
  ImperfectiveThirdPersonDual: {
    id: 20,
    text: "Imperfective Third Person Plural",
  },
  ImperfectiveThirdPersonPlural: {
    id: 21,
    text: "Perfective First Person Singular",
  },
  PerfectiveFirstPersonSingular: {
    id: 22,
    text: "Perfective First Person Dual",
  },
  PerfectiveFirstPersonDual: { id: 23, text: "Perfective First Person Plural" },
  PerfectiveFirstPersonPlural: {
    id: 24,
    text: "Perfective Second Person Singular",
  },
  PerfectiveSecondPersonSingular: {
    id: 25,
    text: "Perfective Second Person Dual",
  },
  PerfectiveSecondPersonDual: {
    id: 26,
    text: "Perfective Second Person Plural",
  },
  PerfectiveSecondPersonPlural: {
    id: 27,
    text: "Perfective Third Person Singular",
  },
  PerfectiveThirdPersonSingular: {
    id: 28,
    text: "Perfective Third Person Dual",
  },
  PerfectiveThirdPersonDual: { id: 29, text: "Perfective Third Person Plural" },
  PerfectiveThirdPersonPlural: { id: 30, text: "Future First Person Singular" },
  FutureFirstPersonSingular: { id: 31, text: "Future First Person Dual" },
  FutureFirstPersonDual: { id: 32, text: "Future First Person Plural" },
  FutureFirstPersonPlural: { id: 33, text: "Future Second Person Singular" },
  FutureSecondPersonSingular: { id: 34, text: "Future Second Person Dual" },
  FutureSecondPersonDual: { id: 35, text: "Future Second Person Plural" },
  FutureSecondPersonPlural: { id: 36, text: "Future Third Person Singular" },
  FutureThirdPersonSingular: { id: 37, text: "Future Third Person Dual" },
  FutureThirdPersonDual: { id: 38, text: "Future Third Person Plural" },
  FutureThirdPersonPlural: { id: 39, text: "Optative First Person Singular" },
  OptativeFirstPersonSingular: { id: 40, text: "Optative First Person Dual" },
  OptativeFirstPersonDual: { id: 41, text: "Optative First Person Plural" },
  OptativeFirstPersonPlural: {
    id: 42,
    text: "Optative Second Person Singular",
  },
  OptativeSecondPersonSingular: { id: 43, text: "Optative Second Person Dual" },
  OptativeSecondPersonDual: { id: 44, text: "Optative Second Person Plural" },
  OptativeSecondPersonPlural: {
    id: 45,
    text: "Optative Third Person Singular",
  },
  OptativeThirdPersonSingular: { id: 46, text: "Optative Third Person Dual" },
  OptativeThirdPersonDual: { id: 47, text: "Optative Third Person Plural" },
  OptativeThirdPersonPlural: { id: 48, text: "Optative Third Person Plural" },
};

const Forms = {
  Generic: 1,
  InalienableNoun: 2,
  BaseVerb: 3,
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export { Forms, WordAttributes, getKeyByValue };

// enum Forms
//     {
//         Generic= 1,
//         InalienableNoun=2,
//         BaseVerb=3,
//     }

// namespace TKDService.Contracts
// {
//     public enum WordAttributes
//     {
//         [Description("Generic")]
//         Generic =0,

//         [Description("First Person Singular")]
//         FirstPersonSingular=1,
//         [Description("First Person Plural")]
//         FirstPersonPlural=2,
//         [Description("Second Person Singular")]
//         SecondPersonSingular=3,
//         [Description("Second Person Plural")]
//         SecondPersonPlural=4,
//         [Description("Third Person Singular")]
//         ThirdPersonSingular=5,
//         [Description("Third Person Plural")]
//         ThirdPersonPlural=6,
//         [Description("Reflexive")]
//         Reflexive=7,
//         [Description("Plural Reflexive")]
//         PluralReflexive=8,
//         [Description("Disjoint Reference")]
//         DisjointReference=9,
//         [Description("Plural Disjoint Reference")]
//         PluralDisjointReference=10,
//         [Description("Reciprocal")]
//         Reciprocal=11,
//         [Description("Indefinite")]
//         Indefinite=12,

//         [Description("Imperfective First Person Singular")]
//         ImperfectiveFirstPersonSingular=13,
//         [Description("Imperfective First Person Dual")]
//         ImperfectiveFirstPersonDual=14,
//         [Description("Imperfective First Person Plural")]
//         ImperfectiveFirstPersonPlural=15,
//         [Description("Imperfective Second Person Singular")]
//         ImperfectiveSecondPersonSingular=16,
//         [Description("Imperfective Second Person Dual")]
//         ImperfectiveSecondPersonDual=17,
//         [Description("Imperfective Second Person Plural")]
//         ImperfectiveSecondPersonPlural=18,
//         [Description("Imperfective Third Person Singular")]
//         ImperfectiveThirdPersonSingular=19,
//         [Description("Imperfective Third Person Dual")]
//         ImperfectiveThirdPersonDual=20,
//         [Description("Imperfective Third Person Plural")]
//         ImperfectiveThirdPersonPlural=21,

//         [Description("Perfective First Person Singular")]
//         PerfectiveFirstPersonSingular=22,
//         [Description("Perfective First Person Dual")]
//         PerfectiveFirstPersonDual=23,
//         [Description("Perfective First Person Plural")]
//         PerfectiveFirstPersonPlural=24,
//         [Description("Perfective Second Person Singular")]
//         PerfectiveSecondPersonSingular=25,
//         [Description("Perfective Second Person Dual")]
//         PerfectiveSecondPersonDual=26,
//         [Description("Perfective Second Person Plural")]
//         PerfectiveSecondPersonPlural=27,
//         [Description("Perfective Third Person Singular")]
//         PerfectiveThirdPersonSingular=28,
//         [Description("Perfective Third Person Dual")]
//         PerfectiveThirdPersonDual=29,
//         [Description("Perfective Third Person Plural")]
//         PerfectiveThirdPersonPlural=30,

//         [Description("Future First Person Singular")]
//         FutureFirstPersonSingular=31,
//         [Description("Future First Person Dual")]
//         FutureFirstPersonDual=32,
//         [Description("Future First Person Plural")]
//         FutureFirstPersonPlural=33,
//         [Description("Future Second Person Singular")]
//         FutureSecondPersonSingular=34,
//         [Description("Future Second Person Dual")]
//         FutureSecondPersonDual=35,
//         [Description("Future Second Person Plural")]
//         FutureSecondPersonPlural=36,
//         [Description("Future Third Person Singular")]
//         FutureThirdPersonSingular=37,
//         [Description("Future Third Person Dual")]
//         FutureThirdPersonDual=38,
//         [Description("Future Third Person Plural")]
//         FutureThirdPersonPlural=39,

//         [Description("Optative First Person Singular")]
//         OptativeFirstPersonSingular=40,
//         [Description("Optative First Person Dual")]
//         OptativeFirstPersonDual=41,
//         [Description("Optative First Person Plural")]
//         OptativeFirstPersonPlural=42,
//         [Description("Optative Second Person Singular")]
//         OptativeSecondPersonSingular=43,
//         [Description("Optative Second Person Dual")]
//         OptativeSecondPersonDual=44,
//         [Description("Optative Second Person Plural")]
//         OptativeSecondPersonPlural=45,
//         [Description("Optative Third Person Singular")]
//         OptativeThirdPersonSingular=46,
//         [Description("Optative Third Person Dual")]
//         OptativeThirdPersonDual=47,
//         [Description("Optative Third Person Plural")]
//         OptativeThirdPersonPlural=48,

//     }
// }

// enum Forms
//     {
//         Generic= 1,
//         InalienableNoun=2,
//         BaseVerb=3,
//     }
