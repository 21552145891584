import React, { Component } from "react";
import Spinner from "../../common/Spinner";
import WordApi from "../word/WordService";
import { WordAttributes } from "../../../domain/Contracts";
class GenericRow extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { id } = this.props.data;
    this.setState({
      rootId: id,
    });
    this.init(id);
  }

  init = async (rootId) => {
    await this.setLoading(true);
    var data = [...(await WordApi.Get(rootId)).data];
    this.initWords(data);
    await this.setLoading(false);
  };

  initWords = async (data) => {
    let words = [];
    data.forEach((word) => {
      words[word.attribute] = word;
    });

    this.setState({
      words,
    });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render() {
    if (!this.state) return <div></div>;
    return (
      <div style={{ padding: "10px" }}>
        <Spinner hidden={this.state.isLoading} />
        <div
          hidden={this.state.isLoading}
          className=" row inalienable-row  w-100"
          style={{ padding: "8px" }}
        >
          <div style={{}} className="row w-100">
            {this.renderOneWord(this.state.words, WordAttributes.Generic.id)}
          </div>
        </div>
      </div>
    );
  }

  renderOneWord(word, first) {
    if (!word) return <div></div>;
    return (
      <div className="col border border-secondary">
        {this.renderWord(" ", word[first] && word[first].text)}
      </div>
    );
  }
  renderWord(title, word) {
    return (
      <div>
        <div className="row" style={{ fontSize: "14px", paddingLeft: "5px" }}>
          {title}
        </div>
        <div
          className="row"
          style={{
            fontSize: "12px",
            paddingLeft: "10px",
            paddingBottom: "15px",
          }}
        >
          {word}
        </div>
      </div>
    );
  }
}

export default GenericRow;
