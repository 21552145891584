import BaseService from "../../../services/BaseService";
const endpoint = "/Topic";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  GetList(SearchText, SkipPages, order, token) {
    return BaseService.Get(endpoint, SearchText, SkipPages, order, 30, token);
  },
  Create(data) {
    return BaseService.CreateMultipart(endpoint, data, multipartHeader);
  },
  Delete(id) {
    return BaseService.Delete(endpoint, id, multipartHeader);
  },
  Update(obj) {
    return BaseService.UpdateMultipart(endpoint, obj, multipartHeader);
  },
};
