import React from "react";
import ButtonPrimary from "./ButtonPrimary";
import InputFileButtonPrimary from "./InputFileButtonPrimary";

const Modal3Button = ({ onNegative, onPositive, disabled, onChooseFile }) => {
  return (
    <div className="row" style={{ paddingTop: "10%", margin: "auto" }}>
      <div className="row col-7">
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            disabled={disabled}
            text="Save"
            onClick={() => onPositive()}
          />
        </div>

        <div style={{ margin: "auto" }}>
          <ButtonPrimary text="Cancel" onClick={() => onNegative()} />
        </div>
      </div>
      <div className="col-5 center">
        <InputFileButtonPrimary
          accept="image/*"
          text="Upload image"
          onChange={onChooseFile}
        />
      </div>
    </div>
  );
};

export default Modal3Button;
