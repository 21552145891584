import React, { Component } from "react";
import MainLayout from "../../layout/MainLayout";
import ActionBar from "../../common/ActionBar";
import CategoryTable from "./CategoryTable";
import CategoryIcon from "./../../../images/categories.svg";
import YesNoPrompt from "../../common/YesNoDialog";
import CategoryCreate from "./CategoryCreate";
import CategoryEdit from "./CategoryEdit";
import api from "./CategoryService";
import _ from "lodash";
import axios from "axios";
class Category extends Component {
  state = {
    pageSize: 10,
    sortColumn: { path: "id", order: "asc" },
    searchText: "",
    skipPages: 0,
    data: [],
    isLoading: false,
    modalIsDeleteOpen: false,
    modalIsCreateOpen: false,
    modalIsEditOpen: false,
    selectedForDelete: {},
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
    this.init();
  };

  token = null;
  componentDidMount() {
    const CancelToken = axios.CancelToken;
    this.token = CancelToken.source();
    this.init();
  }
  init = async () => {
    this.setLoading(true);
    await this.setState({
      data: [],
      skipPages: 0,
    });
    this.debouncedSearch();
    this.setLoading(false);
  };

  fetch = async () => {
    this.setLoading(true);
    const { searchText, skipPages, sortColumn } = this.state;
    var data = [
      ...this.state.data,
      ...(await api.GetList(searchText, skipPages, sortColumn, this.token))
        .data,
    ];
    this.setState({
      data,
    });
    this.setLoading(false);
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.token.cancel("User search again");
    this.debouncedSearch();
  };

  debouncedSearch = _.debounce(function () {
    this.fetch();
  }, 700);

  handelAdd = () => {
    this.setState({ modalIsCreateOpen: true });
  };

  handelEdit = (data) => {
    this.setState({ modalIsEditOpen: true, selectedForEdit: data });
  };

  handelDelete = (data) => {
    this.setState({ modalIsDeleteOpen: true, selectedForDelete: data });
  };
  handelFetchMore = async () => {
    const { skipPages } = this.state;
    await this.setState({
      skipPages: skipPages + 1,
    });
    this.fetch();
  };

  handelYesDelete = async (item) => {
    await api.Delete(item.id);
    this.setState({ modalIsDeleteOpen: false });
    this.init();
  };

  handelNoDelete = () => {
    this.setState({ modalIsDeleteOpen: false });
  };

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }

  handelCreateSubmit = async (data) => {
    await api.Create(data);
    this.handelCreateCancel();
    this.init();
  };
  handelCreateCancel = () => {
    this.setState({ modalIsCreateOpen: false });
  };

  handelEditSubmit = async (data) => {
    await api.Update(data);
    this.handelEditCancel();
    this.setState({ selectedForEdit: null });
    this.init();
  };

  handelEditCancel = () => {
    this.setState({ modalIsEditOpen: false });
  };

  renderMainLayoutContent() {
    const {
      sortColumn,
      data,
      isLoading,
      modalIsDeleteOpen,
      modalIsCreateOpen,
      modalIsEditOpen,
      selectedForDelete,
      selectedForEdit,
    } = this.state;

    return (
      <div>
        <div>
          <ActionBar
            icon={CategoryIcon}
            title="Categories"
            onAdd={this.handelAdd}
            onSearch={this.handleSearch}
          />
        </div>
        <CategoryTable
          items={data}
          onEdit={this.handelEdit}
          onDelete={this.handelDelete}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          isLoading={isLoading}
          skipPages={this.state.skipPages}
          pageSize={this.state.pageSize}
          onFetchMore={this.handelFetchMore}
        />
        <YesNoPrompt
          isOpen={modalIsDeleteOpen}
          title="Are you sure?"
          data={selectedForDelete}
          onPositive={this.handelYesDelete}
          onNegative={this.handelNoDelete}
        />

        <CategoryCreate
          key={"Add_" + modalIsCreateOpen}
          isOpen={modalIsCreateOpen}
          onPositive={this.handelCreateSubmit}
          onNegative={this.handelCreateCancel}
          onClose={this.handelCreateCancel}
        />

        <CategoryEdit
          key={selectedForEdit && selectedForEdit.id}
          isOpen={modalIsEditOpen}
          data={selectedForEdit}
          onPositive={this.handelEditSubmit}
          onNegative={this.handelEditCancel}
          onClose={this.handelEditCancel}
        />
      </div>
    );
  }
}

export default Category;
