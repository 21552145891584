import React, { Component } from "react";
import Table from "../../common/Table";
import ButtonPrimary from "../../common/ButtonPrimary";
class EnglishWordTable extends Component {
  columns = [
    {
      path: "word",
      lable: "word",
      headerStyle: { width: "75%", paddingLeft: "25px" },
      content: (item) => (
        <div
          className="table-col"
          style={{
            width: "75%",
            paddingLeft: "25px",
          }}
        >
          {item.word}
        </div>
      ),
      key: "name",
    },
    {
      path: "",
      lable: "",
      key: "edit",
      headerStyle: { width: "12.5%" },
      content: (item) => (
        <div className="table-col" style={{ width: "12.5%" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div>
      ),
    },
    {
      path: "",
      lable: "",
      key: "delete",
      headerStyle: { width: "12.5%" },
      content: (item) => (
        <div className="table-col" style={{ width: "12.5%" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      ),
    },
  ];
  onSort(sortColumn) {
    this.props.onSort(sortColumn);
  }
  state = {};
  render() {
    const { items, sortColumn, onSort, onFetchMore, isLoading, pageSize } =
      this.props;
    return (
      <Table
        pageSize={pageSize}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
        columns={this.columns}
        rowClassName="table-item"
        isLoading={isLoading}
        onFetchMore={onFetchMore}
      />
    );
  }
}
export default EnglishWordTable;
