import React from "react";
const CuredButton = ({ text, onClick, disabled = false, ...props }) => {
  console.log(disabled);
  return (
    <div disabled={disabled} className="cured-button" onClick={onClick}>
      <label className="cured-button-text center">{text}</label>
    </div>
  );
};

export default CuredButton;
