import React from "react";
import Modal from "react-modal";
import Spinner from "./Spinner";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
  },
};

const Progressbar = ({ title, isOpen }) => {
  return (
    <Modal ariaHideApp={false} style={customStyles} isOpen={isOpen}>
      <div className="modal-background">
        <div className="model-content center">{title}</div>
        <Spinner />
      </div>
    </Modal>
  );
};

export default Progressbar;
