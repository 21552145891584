import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/AuthService";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  console.log("ProtectedLogin");
  const currentUser = auth.getCurrentUser();
  if (currentUser && currentUser.exp < Date.now() / 1000) {
    localStorage.clear();
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
