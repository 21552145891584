import BaseService from "../../../services/BaseService";
const endpoint = "/Level";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  GetAll(order) {
    return BaseService.GetAll(endpoint, order);
  },
  Get(SearchText, SkipPages, order, pageSize, token) {
    return BaseService.Get(
      endpoint,
      SearchText,
      SkipPages,
      order,
      pageSize,
      token
    );
  },
  Create(data) {
    return BaseService.CreateMultipart(endpoint, data, multipartHeader);
  },
  Delete(id) {
    return BaseService.Delete(endpoint, id);
  },
  Update(data) {
    return BaseService.UpdateMultipart(endpoint, data, multipartHeader);
  },
};
