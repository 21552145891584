import React from "react";
import Joi from "joi-browser";
import auth from "../../services/AuthService";
import Password from "./../common/Password";
import RememberMe from "./../common/RememberMe";
import Form from "./../common/Form";
import EnvelopeIcon from "./../../images/envelope.svg";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

class LoginForm extends Form {
  state = {
    data: {
      //email: "user@example.com",
      //password: "asd123F$",
      email: "",
      password: "",
    },
    errors: {},
    isRememberMe: false,
  };

  schema = {
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().required().min(6).label("Password"),
  };

  saveCookie = (name, value) => {
    //const cookies = new Cookies();
    //cookies.set(name, value, { path: "/" });
  };

  handleRememberMe = (checked) => {
    //saveCookie("rememberMe", checked);
    //
    this.setState({ isRememberMe: checked });
  };

  toastMessage(message) {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  doSubmit = async () => {
    const { email, password, isRememberMe } = this.state.data;
    try {
      await auth.login(email, password, isRememberMe);
      var role = auth.getCurrentUserRole();
      if (role !== "Admin") {
        this.toastMessage(
          "Only Admin users have access for this functionality!"
        );
        auth.logout();
      } else {
        const { state } = this.props.location;
        window.location = state ? state.form.pathname : "/";
        this.props.history.push("/");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
        this.toastMessage(ex.response.data.Message);
      }
    }
  };

  handleForgetPassword = () => {
    this.props.history.push("/ForgotPassword");
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;
    return (
      <div className="background-mount wrapper">
        <form onSubmit={this.handleSubmit}>
          <div
            className="purple-color center"
            style={{ fontSize: 24, fontWeight: "bold" }}
          >
            Sign in
          </div>
          {this.renderInput("email", "Email", EnvelopeIcon)}
          {this.renderPassword()}
          <div>{this.renderSubmitButton("SIGN IN")}</div>
        </form>
        <div
          className="row"
          style={{
            minWidth: 400,
            color: "white",
            marginTop: 20,
          }}
        >
          <div className="col">
            <RememberMe
              checked={this.state.isRememberMe}
              onRememberMe={this.handleRememberMe}
            />
          </div>
          {/* <div className="col clickable" onClick={this.handleForgetPassword}>
            Forgot password?
          </div> */}
        </div>
      </div>
    );
  }

  renderPassword() {
    const { data, errors } = this.state;
    return (
      <Password
        value={data.password}
        onChange={this.handelChange}
        name="password"
        title="Password"
        error={errors.password}
        style={{ marginTop: 10 }}
      />
    );
  }
}

export default LoginForm;
