import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal3Button from "../../common/Modal3Button";
import Joi from "joi-browser";
import NoImageIcon from "../../../images/no_image.svg";
import Progressbar from "../../common/Progressbar";
import { toast } from "react-toastify";

class RootEdit extends BaseModal {
  imageNotSelectMessage = "Please upload an image for the root";
  state = {
    data: {
      LevelId: "",
      CategoryId: "",
      Form: 0,
      image: null,
      rowVersion: "",
      RootWordId: null,
      id: "",
    },
    image: null,
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    id: Joi.any(),
    rowVersion: Joi.any(),
    LevelId: Joi.string().required().label("Level"),
    CategoryId: Joi.string().required().label("Category"),
    Form: Joi.number().required().label("Form"),
    RootWordId: Joi.any(),
    image: Joi.any().label("image"),
  };

  componentDidMount = async () => {
    const { data } = this.props;
    if (data) {
      if (data != null) {
        this.setState({
          data: {
            id: data.id,
            Form: data.form,
            rowVersion: data.rowVersion,
            LevelId: data.level.id,
            CategoryId: data.category.id,
          },
        });
        let blob = await fetch(data.imageUrl).then((r) => r.blob());
        var tempdata = this.state.data;
        tempdata.image = blob;
        this.setState({
          data: tempdata,
          image: URL.createObjectURL(blob),
        });
      }
    }
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Edit A Root", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  renderBody() {
    const { levels, categories, forms } = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-7">
            <div className="col-8" style={{ marginTop: "80px" }}>
              {this.renderSelect("LevelId", "Level", levels)}
            </div>
            <div className="col-8" style={{ marginTop: "80px" }}>
              {this.renderSelect("CategoryId", "Category", categories)}
            </div>
            <div className="col-8" style={{ marginTop: "80px" }}>
              {this.renderSelect("Form", "Form", forms)}
            </div>
          </div>
          <div className="col-5 img-center">
            {this.renderImage(
              "image",
              this.state.image,
              NoImageIcon,
              this.imageNotSelectMessage
            )}
          </div>
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal3Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
            onChooseFile={this.handelChooseFile}
          />
        </div>

        <Progressbar isOpen={this.progressbarIsShow} title="Please wait" />
      </div>
    );
  }

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  toastImageMessage() {
    toast.error(this.imageNotSelectMessage, {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };
    if (data.image === null) {
      this.toastImageMessage();

      return;
    }
    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  handelChooseFile = async (event) => {
    var image = event.target.files[0];
    this.setState({
      data: {
        ...this.state.data,
        image,
      },
      image: URL.createObjectURL(image),
    });
  };

  async clearData() {
    this.setState({
      data: {
        LevelId: "",
        CategoryId: "",
        Form: "",
        image: null,
        rowVersion: "",
        id: "",
      },
      image: null,
    });
  }
}

export default RootEdit;
