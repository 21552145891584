import React from "react";
import ThreePartLayout from "./ThreePartLayout";
import NavigationBar from "./NavigationBar";
import Header from "./Header";

const MainLayout = ({ content }) => {
  return (
    <ThreePartLayout
      header={<Header />}
      navigationBar={<NavigationBar />}
      content={content}
    />
  );
};

export default MainLayout;
