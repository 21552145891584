import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal2Button from "../../common/Modal2Button";
import Joi from "joi-browser";
import Progressbar from "../../common/Progressbar";
import api from "../englishWords/EnglishWordService";
import { AsyncPaginate } from "react-select-async-paginate";
import ItemDeleteable from "../../common/ItemDeleteable";
import _ from "lodash";
import axios from "axios";

class EnglishWordsSetter extends BaseModal {
  state = {
    data: {
      EnglishWords: [],
    },
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    EnglishWords: Joi.array().items(Joi.object().required()),
  };
  token = null;
  componentDidMount() {
    const { data } = this.props;
    const EnglishWords = data.englishWords;
    const CancelToken = axios.CancelToken;
    this.token = CancelToken.source();
    this.setState({ data: { EnglishWords } });
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Set Words", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const responseJSON = await api.GetList(searchQuery, page, "", this.token);

    const result = {
      options: responseJSON.data,
      hasMore: responseJSON.data.length >= 1,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
    return result;
  };

  onChange = (option) => {
    var word = { id: option.id, word: option.word };
    var found = _.find(this.state.data.EnglishWords, function (n) {
      return n.id === word.id;
    });
    if (!found) {
      var EnglishWords = [...this.state.data.EnglishWords, word];
      this.setState({ data: { EnglishWords } });
    }
  };

  onWordDelete = (word) => {
    var EnglishWords = this.state.data.EnglishWords;
    _.remove(EnglishWords, function (n) {
      return n.id === word.id;
    });

    this.setState({ data: { EnglishWords } });
  };

  renderWord(EnglishWords) {
    return (
      <ItemDeleteable
        title={EnglishWords.word}
        onRemove={() => this.onWordDelete(EnglishWords)}
      />
    );
  }

  renderBody() {
    const { EnglishWords } = this.state.data;
    return (
      <div>
        <div className="row">
          <div className="col-5" style={{ marginTop: "30px" }}>
            <AsyncPaginate
              key="Selector"
              loadOptions={this.loadOptions}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.word}
              onChange={this.onChange}
              isSearchable={true}
              placeholder="Select Word"
              additional={{
                page: 0,
              }}
            />
          </div>
          <div className="col-6" style={{ marginTop: "30px", height: "250px" }}>
            <div className="row row-cols-auto">
              {EnglishWords &&
                EnglishWords.map((word) => this.renderWord(word))}
            </div>
          </div>
        </div>
        <div className="" style={{ marginTop: "30px", marginBottom: "10px" }}>
          <Modal2Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait?"
        />
      </div>
    );
  }

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    await this.setProgressbarVisibility(true);
    const { onPositive, data } = this.props;
    let EnglishWords = [];
    _.forEach(this.state.data.EnglishWords, function (word) {
      EnglishWords = [...EnglishWords, word.id];
    });
    let dataForSave = { EnglishWords, RootId: data.id };
    await this.clearData();
    await onPositive(dataForSave);
    await this.setProgressbarVisibility(false);
  };

  clearData = async () => {
    this.setState({
      data: {
        EnglishWords: [],
      },
    });
  };
}

export default EnglishWordsSetter;
