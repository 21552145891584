import React, { Component } from "react";
import MainLayout from "../../layout/MainLayout";
import ActionBar from "../../common/ActionBar";
import ButtonPrimary from "../../common/ButtonPrimary";
import LevelIcon from "./../../../images/level.svg";
import YesNoPrompt from "../../common/YesNoDialog";
import api from "./UserService";
import UserGrid from "./UserGrid";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import auth from "../../../services/AuthService";
class Profile extends Component {
  state = {
    pageSize: 10,
    searchText: "",
    skipPages: 0,
    data: [],
    isLoading: false,
    modalIsDeleteOpen: false,
    modalIsUserCreateOpen: false,
    modalIsAdminCreateOpen: false,
    selectedForDelete: {},
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  componentDidMount() {
    this.fetch();
  }

  init = async () => {
    this.setLoading(true);

    var data = [...(await api.Get("", 0)).data];
    this.setState({
      data,
    });

    this.setLoading(false);
  };

  fetch = async () => {
    this.setLoading(true);
    const { searchText, skipPages } = this.state;
    var currentUser = auth.getCurrentUser();
    var data = [
      ...this.state.data,
      ...(await api.Get(searchText, skipPages)).data,
    ];
    this.setState({
      data,
    });
    this.setLoading(false);
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.fetch();
  };

  handelAddUser = () => {
    this.setState({ modalIsUserCreateOpen: true });
  };

  handelAddAdmin = () => {
    this.setState({ modalIsAdminCreateOpen: true });
  };

  handelDelete = (data) => {
    this.setState({ modalIsDeleteOpen: true, selectedForDelete: data });
  };

  handelFetchMore = async () => {
    const { skipPages } = this.state;
    await this.setState({
      skipPages: skipPages + 1,
    });
    this.fetch();
  };

  handelYesDelete = async (item) => {
    await api.Delete(item.id);
    this.setState({ modalIsDeleteOpen: false });
    this.init();
  };

  handelNoDelete = () => {
    this.setState({ modalIsDeleteOpen: false });
  };

  handelCreateSubmitUser = async (data) => {
    await api.CreateUser(data);
    this.handelCreateCancelUser();
    this.init();
  };

  handelCreateSubmitAdmin = async (data) => {
    await api.CreateAdmin(data);
    this.handelCreateCancelAdmin();
    this.init();
  };
  handelCreateCancelUser = () => {
    this.setState({ modalIsUserCreateOpen: false });
    this.setState({ selectedForEdit: null });
  };
  handelCreateCancelAdmin = () => {
    this.setState({ modalIsAdminCreateOpen: false });
    this.setState({ selectedForEdit: null });
  };

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }

  renderMainLayoutContent() {
    const {
      data,
      isLoading,
      modalIsDeleteOpen,
      modalIsUserCreateOpen,
      modalIsAdminCreateOpen,
      selectedForDelete,
    } = this.state;

    return (
      <div>
        <div>
          <ActionBar
            icon={LevelIcon}
            title="My Profile"
            addDisabled={true}
            onSearch={this.handleSearch}
          />
        </div>
        <div className="col">
          <ButtonPrimary
            style={{ marginLeft: 10 }}
            text="Add User"
            onClick={() => this.handelAddUser()}
          />
          <ButtonPrimary
            style={{ marginLeft: 10 }}
            text="Add Admin"
            onClick={() => this.handelAddAdmin()}
          />
        </div>
        <UserGrid
          items={data}
          onEdit={this.handelEdit}
          onDelete={this.handelDelete}
          isLoading={isLoading}
          skipPages={this.state.skipPages}
          pageSize={this.state.pageSize}
          onFetchMore={this.handelFetchMore}
        />
        <YesNoPrompt
          isOpen={modalIsDeleteOpen}
          title="Are you sure?"
          data={selectedForDelete}
          onPositive={this.handelYesDelete}
          onNegative={this.handelNoDelete}
        />

        <UserCreate
          key={"Add_" + modalIsUserCreateOpen}
          type="user"
          isOpen={modalIsUserCreateOpen}
          onPositive={this.handelCreateSubmitUser}
          onNegative={this.handelCreateCancelUser}
          onClose={this.handelCreateCancelUser}
        />

        <UserCreate
          key={"Add_" + modalIsAdminCreateOpen}
          type="admin user"
          isOpen={modalIsAdminCreateOpen}
          onPositive={this.handelCreateSubmitAdmin}
          onNegative={this.handelCreateCancelAdmin}
          onClose={this.handelCreateCancelAdmin}
        />
      </div>
    );
  }
}

export default Profile;
