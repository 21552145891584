import React from "react";
import PlayIcon from "../../images/play.svg";
import StopIcon from "../../images/stop.svg";
import SpeakerDisableIcon from "../../images/speaker_disable.svg";

class Music extends React.Component {
  state = {
    play: false,
  };
  audio = new Audio();

  componentDidMount() {
    this.audio.addEventListener("ended", () => this.setState({ play: false }));
  }

  componentWillUnmount() {
    this.audio.removeEventListener("ended", () =>
      this.setState({ play: false })
    );
  }

  togglePlay = () => {
    const { audio } = this.props;
    if (audio !== null) this.audio.src = audio;
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
    });
  };

  render() {
    const { isEnable } = this.props;
    return (
      <img
        onClick={this.togglePlay}
        src={
          isEnable ? SpeakerDisableIcon : this.state.play ? StopIcon : PlayIcon
        }
        alt="Icon"
        className="nav-button-icon"
      ></img>
    );
  }
}

export default Music;
