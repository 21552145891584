import React, { Component } from "react";
import MainLayout from "../../layout/MainLayout";
import ActionBar from "../../common/ActionBar";
import RootsIcon from "./../../../images/roots.svg";
import categoryApi from "../category/CategoryService";
import levelApi from "../level/LevelService";
import rootApi from "./RootService";
import RootTable from "./RootTable";
import YesNoPrompt from "../../common/YesNoDialog";
import RootCreate from "./RootCreate";
import RootEdit from "./RootEdit";
import { Forms, getKeyByValue } from "../../../domain/Contracts";
import EnglishWordsSetter from "./EnglishWordsSetter";
import TopicSetter from "./TopicSetter";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
class Root extends Component {
  state = {
    pageSize: 10,
    currentPage: 0,
    sortColumn: { path: "rootWord", order: "asc" },
    searchText: "",
    skipPages: 0,
    data: [],
    isLoading: false,
    modalIsDeleteOpen: false,
    modalIsCreateOpen: false,
    modalIsEditOpen: false,
    modalIsEnglishWordsOpen: false,
    selectedForEnglishWords: [],
    modalIsTopicsOpen: false,
    selectedForTopics: [],
    isActionBarAddButtonHide: false,
    selectedForDelete: {},
  };
  forms = [
    {
      _id: Forms.Generic,
      name: "Generic",
    },
    {
      _id: Forms.InalienableNoun,
      name: "InalienableNoun",
    },
    {
      _id: Forms.BaseVerb,
      name: "BaseVerb",
    },
  ];

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
    this.init();
  };

  token = null;
  componentDidMount() {
    const CancelToken = axios.CancelToken;
    this.token = CancelToken.source();
    this.init();
  }

  init = async () => {
    this.setLoading(true);
    let categories = await this.getCategories();
    let levels = await this.getLevels();

    await this.setState({
      categories,
      skipPages: 0,
      levels,
      isActionBarAddButtonHide: false,
      data: [],
    });
    this.debouncedSearch();
    this.setLoading(false);
  };

  fetch = async () => {
    this.setLoading(true);
    const { searchText, skipPages, sortColumn } = this.state;
    let all = await rootApi.GetList(
      searchText,
      skipPages,
      sortColumn,
      this.token
    );
    let addData = [];
    if (all) {
      addData = all.data;
    }
    var data = [...this.state.data, ...addData];
    this.setState({
      data,
    });
    this.setLoading(false);
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.token.cancel("User search again");
    this.debouncedSearch();
  };

  debouncedSearch = _.debounce(function () {
    this.fetch();
  }, 700);

  handelAdd = () => {
    this.setState({ modalIsCreateOpen: true });
  };

  handelEdit = (data) => {
    this.setState({ modalIsEditOpen: true, selectedForEdit: data });
  };

  handelDelete = (data) => {
    this.setState({ modalIsDeleteOpen: true, selectedForDelete: data });
  };

  handelFetchMore = async () => {
    const { skipPages } = this.state;
    await this.setState({
      skipPages: skipPages + 1,
    });
    this.fetch();
  };

  handelYesDelete = async (item) => {
    await rootApi.Delete(item.id);
    this.setState({ modalIsDeleteOpen: false });
    this.init();
  };

  handelNoDelete = () => {
    this.setState({ modalIsDeleteOpen: false });
  };

  handelCreateSubmit = async (data) => {
    var root = await rootApi.Create(data);
    data.id = root.data;
    data.form = data.Form;
    this.handelCreateCancel();
    this.setState({ selectedForEdit: null });
    this.handelDetails(data);
  };
  handelCreateCancel = () => {
    this.setState({ modalIsCreateOpen: false });
  };

  handelEditSubmit = async (data) => {
    await rootApi.Update(data);
    this.handelEditCancel();
    this.init();
  };

  handelEditCancel = () => {
    this.setState({ modalIsEditOpen: false });
  };

  handelEnglishWords = (data) => {
    this.setState({
      modalIsEnglishWordsOpen: true,
      selectedForEnglishWords: data,
    });
  };

  handelEnglishWordsCancel = () => {
    this.setState({
      modalIsEnglishWordsOpen: false,
      selectedForEnglishWords: [],
    });
  };

  handelEnglishWordsSubmit = async (data) => {
    try {
      await rootApi.SetEnglishWord(data);
    } catch (error) {
      let errorMessage =
        " \n" + (error.response ? error.response.data.Message : error.message);
      this.showError(errorMessage);
    }

    this.handelEnglishWordsCancel();
    this.init();
  };

  handelTopics = (data) => {
    this.setState({ modalIsTopicsOpen: true, selectedForTopics: data });
  };

  handelTopicsCancel = () => {
    this.setState({ modalIsTopicsOpen: false, selectedForTopics: [] });
  };

  handelTopicsSubmit = async (data) => {
    try {
      await rootApi.SetTopics(data);
    } catch (error) {
      let errorMessage =
        " \n" + (error.response ? error.response.data.Message : error.message);
      this.showError(errorMessage);
    }

    this.handelTopicsCancel();
    this.init();
  };

  handelDetails = (data) => {
    var formName = getKeyByValue(Forms, data.form);

    this.props.history.push({
      pathname: "/" + formName,
      search: "?rootId=" + data.id,
      state: { rootId: data.id },
    });
  };

  showError(message) {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelShowDetails = async (item) => {
    const { data } = this.state;
    item.isShowDetails = !item.isShowDetails;
    var newData = _.map(data, function (a) {
      return a.id === item.id ? item : a;
    });
    await this.setState({ data: newData });
  };
  async getLevels() {
    let all = await levelApi.GetAll();
    let levelsRow = [];
    if (all) {
      levelsRow = all.data;
    }

    let levels = [];
    levelsRow.forEach((element) => {
      levels.push({ _id: element.id, name: element.name });
    });
    return levels;
  }

  async getCategories() {
    let all = await categoryApi.GetAll();
    let categoriesRaw = [];
    if (all) {
      categoriesRaw = all.data;
    }
    let categories = [];
    categoriesRaw.forEach((element) => {
      categories.push({ _id: element.id, name: element.name });
    });
    return categories;
  }

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }

  renderMainLayoutContent() {
    const {
      sortColumn,
      data,
      isLoading,
      modalIsDeleteOpen,
      modalIsCreateOpen,
      modalIsEditOpen,
      selectedForDelete,
      modalIsEnglishWordsOpen,
      selectedForEdit,
      selectedForEnglishWords,
      selectedForTopics,
      modalIsTopicsOpen,
    } = this.state;

    return (
      <div>
        <div>
          <ActionBar
            icon={RootsIcon}
            title="Roots"
            onAdd={this.handelAdd}
            addDisabled={this.state.isActionBarAddButtonHide}
            onSearch={this.handleSearch}
          />
        </div>
        <RootTable
          items={data}
          onEdit={this.handelEdit}
          onDelete={this.handelDelete}
          onDetails={this.handelDetails}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          isLoading={isLoading}
          pageSize={this.state.pageSize}
          onEnglishWords={this.handelEnglishWords}
          onTopics={this.handelTopics}
          showDetails={this.handelShowDetails}
          onFetchMore={this.handelFetchMore}
        />

        <YesNoPrompt
          isOpen={modalIsDeleteOpen}
          title="Are you sure?"
          data={selectedForDelete}
          onPositive={this.handelYesDelete}
          onNegative={this.handelNoDelete}
        />

        <RootCreate
          key={"Add_" + modalIsCreateOpen}
          forms={this.forms}
          levels={this.state.levels}
          categories={this.state.categories}
          isOpen={modalIsCreateOpen}
          onPositive={this.handelCreateSubmit}
          onNegative={this.handelCreateCancel}
          onClose={this.handelCreateCancel}
        />

        <RootEdit
          forms={this.forms}
          levels={this.state.levels}
          categories={this.state.categories}
          key={selectedForEdit && selectedForEdit.id}
          isOpen={modalIsEditOpen}
          data={selectedForEdit}
          onPositive={this.handelEditSubmit}
          onNegative={this.handelEditCancel}
          onClose={this.handelEditCancel}
        />

        <EnglishWordsSetter
          key={selectedForEnglishWords && selectedForEnglishWords.englishWords}
          isOpen={modalIsEnglishWordsOpen}
          data={selectedForEnglishWords}
          onPositive={this.handelEnglishWordsSubmit}
          onNegative={this.handelEnglishWordsCancel}
          onClose={this.handelEnglishWordsCancel}
        />

        <TopicSetter
          key={selectedForTopics && selectedForTopics.topics}
          isOpen={modalIsTopicsOpen}
          data={selectedForTopics}
          onPositive={this.handelTopicsSubmit}
          onNegative={this.handelTopicsCancel}
          onClose={this.handelTopicsCancel}
        />
      </div>
    );
  }
}

export default Root;
