import React from "react";
import InputFileButtonPrimary from "./InputFileButtonPrimary";
import Music from "../common/Music";
import MusicDownload from "../common/MusicDownload";

const WordInputCreate = (props) => {
  const { word, onChooseVoice, onChange, error, ...rest } = props;
  return (
    <React.Fragment>
      <div className="form-group">
        <label className="word-input-title">{word.item}</label>
        <div className="row">
          <div className="col-9">
            <div className="form-control word-div-input shadow-sm">
              <input
                {...rest}
                onChange={(e) => onChange(word, e.currentTarget.value)}
                name={word.name}
                value={word.text}
                className="word-input-in-div"
                id={word.name}
              />
              {word.voice ? (
                <Music isEnable={word.voice === null} audio={word.voice} />
              ) : (
                word.audioUrl && <MusicDownload url={word.audioUrl} />
              )}
            </div>
            {error && (
              <div className="input-error ">
                <label>{error}</label>
              </div>
            )}
          </div>
          <div className="col-3 " style={{ marginTtop: "auto" }}>
            <InputFileButtonPrimary
              accept="audio/*"
              text="Upload Audio"
              onChange={(e) => onChooseVoice(word, e)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WordInputCreate;
