import React, { Component } from "react";
import InputFileButtonPrimary from "./InputFileButtonPrimary";
import Music from "../common/Music";
import Spinner from "./Spinner";
class WordInputEdit extends Component {
  state = {
    isLoading: false,
  };
  componentDidMount = async () => {
    const { word, onDownloadVoice } = this.props;
    if (word.audioUrl) {
      await this.setState({ isLoading: true });
      let voice = await fetch(word.audioUrl).then((r) => r.blob());
      onDownloadVoice(word, voice);
      await this.setState({ isLoading: false });
    }
  };
  render() {
    const { word, onChooseVoice, onChange, error, ...rest } = this.props;
    return (
      <React.Fragment>
        <div className="form-group">
          <label className="word-input-title">{word.item}</label>
          <div className="row">
            <div className="col-9">
              <div className="form-control word-div-input shadow-sm">
                <input
                  className="col-9"
                  {...rest}
                  onChange={(e) => onChange(word, e.currentTarget.value)}
                  name={word.name}
                  value={word.text}
                  className="word-input-in-div"
                  id={word.name}
                />
                <Music isEnable={word.voice === null} audio={word.voice} />
                <Spinner
                  size="1rem"
                  margin="3px"
                  className="word-input-in-div"
                  hidden={this.state.isLoading}
                />
              </div>
              {error && (
                <div className="input-error ">
                  <label>{error}</label>
                </div>
              )}
            </div>
            <div className="col-3 " style={{ marginTtop: "auto" }}>
              <InputFileButtonPrimary
                accept="audio/*"
                text="Upload Audio"
                onChange={(e) => onChooseVoice(word, e)}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WordInputEdit;
