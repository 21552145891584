import React from "react";
import ok from "./../../images/user_tik.svg";
import nok from "./../../images/user_not_tik.svg";
const Check = ({
  checked = false,
  width = 16,
  onClick,
  disable = true,
  ...rest
}) => {
  var cursor = !disable ? "pointer" : "";
  return (
    <React.Fragment>
      {checked ? (
        <img
          onClick={onClick}
          src={ok}
          {...rest}
          className="img-fluid"
          disable={disable}
          style={{ width: width, cursor: cursor }}
        />
      ) : (
        <img
          onClick={onClick}
          src={nok}
          {...rest}
          className="img-fluid"
          disable={disable}
          style={{ width: width, cursor: cursor }}
        />
      )}
    </React.Fragment>
  );
};

export default Check;
