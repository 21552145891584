import React from "react";
import BaseModal from "../../common/BaseModal";
import Modal2Button from "../../common/Modal2Button";
import Joi from "joi-browser";
import NoImageIcon from "../../../images/no_image.svg";
import Progressbar from "../../common/Progressbar";
import { toast } from "react-toastify";
class EnglishWordCreate extends BaseModal {
  state = {
    data: {
      word: "",
    },
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    word: Joi.string().required().label("Word"),
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Add word", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  renderBody() {
    return (
      <div>
        <div className="col-12">{this.renderTextArea("word", "Word")}</div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal2Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  toastImageMessage() {
    toast.error("Please select a photo for topic", {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };

    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  clearData() {
    this.setState({
      data: {
        word: "",
      },
    });
  }
}

export default EnglishWordCreate;
