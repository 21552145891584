import React from "react";
const Spinner = (props) => {
  const { hidden, size = "3rem", margin = 10 } = props;
  return (
    <div hidden={!hidden} className="center" style={{ margin: margin }}>
      <div
        className="spinner-border text-primary"
        style={{ width: size, height: size }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
