import React from "react";

function Input(props) {
  const { title, icon, name, error, ...rest } = props;
  return (
    <React.Fragment>
      <div className="form-group">
        <label className="input-title">{title}</label>
        <div className="form-control div-input shadow-sm">
          <img src={icon} alt="icon" className="icon-input-left"></img>
          <input {...rest} name={name} className="input-in-div" id={name} />
        </div>
        {error && (
          <div className="input-error ">
            <label>{error}</label>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Input;
