import React, { Component } from "react";
import WordApi from "../word/WordService";
import { WordAttributes } from "../../../domain/Contracts";
class BaseVerbRow extends Component {
  componentDidMount() {
    const { id } = this.props.data;
    this.setState({
      rootId: id,
    });
    this.init(id);
  }

  setLoading = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  init = async (rootId) => {
    this.setLoading(true);
    var data = await WordApi.Get(rootId);
    this.initWords(data.data);
    this.setLoading(false);
  };

  initWords = async (data) => {
    let words = [];
    data.forEach((word) => {
      words[word.attribute] = word;
    });

    this.setState({
      words,
    });
  };

  render() {
    if (!this.state) return <div></div>;
    return (
      <div className=" row inalienable-row " style={{ padding: "10px" }}>
        <div style={{}} className="row w-100">
          {this.renderHeader("Imprefective", 1)}
          {this.renderHeader("Prefetvie", 1)}
          {this.renderHeader("Future", 1)}
          {this.renderHeader("Optative", 1)}
        </div>
        <div style={{}} className="row w-100">
          {this.renderWords(
            this.state.words,
            WordAttributes.ImperfectiveFirstPersonSingular.id,
            WordAttributes.ImperfectiveFirstPersonDual.id,
            WordAttributes.ImperfectiveFirstPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.PerfectiveFirstPersonSingular.id,
            WordAttributes.PerfectiveFirstPersonDual.id,
            WordAttributes.PerfectiveFirstPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.FutureFirstPersonSingular.id,
            WordAttributes.FutureFirstPersonDual.id,
            WordAttributes.FutureFirstPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.OptativeFirstPersonSingular.id,
            WordAttributes.OptativeFirstPersonDual.id,
            WordAttributes.OptativeFirstPersonPlural.id
          )}
        </div>
        <div style={{}} className="row w-100">
          {this.renderPerson("Second person", 2)}
          {this.renderPerson("Second person", 2)}
          {this.renderPerson("Second person", 2)}
          {this.renderPerson("Second person", 2)}
        </div>
        <div style={{}} className="row w-100">
          {this.renderWords(
            this.state.words,
            WordAttributes.ImperfectiveSecondPersonSingular.id,
            WordAttributes.ImperfectiveSecondPersonDual.id,
            WordAttributes.ImperfectiveSecondPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.PerfectiveSecondPersonSingular.id,
            WordAttributes.PerfectiveSecondPersonDual.id,
            WordAttributes.PerfectiveSecondPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.FutureSecondPersonSingular.id,
            WordAttributes.FutureSecondPersonDual.id,
            WordAttributes.FutureSecondPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.OptativeSecondPersonSingular.id,
            WordAttributes.OptativeSecondPersonDual.id,
            WordAttributes.OptativeSecondPersonPlural.id
          )}
        </div>

        <div style={{}} className="row w-100">
          {this.renderPerson("Third person", 3)}
          {this.renderPerson("Third person", 3)}
          {this.renderPerson("Third person", 3)}
          {this.renderPerson("Third person", 3)}
        </div>

        <div style={{}} className="row w-100">
          {this.renderWords(
            this.state.words,
            WordAttributes.ImperfectiveThirdPersonSingular.id,
            WordAttributes.ImperfectiveThirdPersonDual.id,
            WordAttributes.ImperfectiveThirdPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.PerfectiveThirdPersonSingular.id,
            WordAttributes.PerfectiveThirdPersonDual.id,
            WordAttributes.PerfectiveThirdPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.FutureThirdPersonSingular.id,
            WordAttributes.FutureThirdPersonDual.id,
            WordAttributes.FutureThirdPersonPlural.id
          )}
          {this.renderWords(
            this.state.words,
            WordAttributes.OptativeThirdPersonSingular.id,
            WordAttributes.OptativeThirdPersonDual.id,
            WordAttributes.OptativeThirdPersonPlural.id
          )}
        </div>
      </div>
    );
  }

  renderHeader(verbName, colorIndex) {
    let indexColorClasss = "dot-circle p" + colorIndex + "-color";
    return (
      <div className="col border border-secondary">
        {verbName}
        <div className="row" style={{ padding: "5px" }}>
          <div className={indexColorClasss} />
          <div className="col">First person</div>
        </div>
      </div>
    );
  }

  renderPerson(personTitle, colorIndex) {
    let indexColorClasss = "dot-circle p" + colorIndex + "-color";
    return (
      <div className="col border border-secondary">
        <div className="row" style={{ padding: "5px" }}>
          <div className={indexColorClasss} />
          <div className="col">{personTitle}</div>
        </div>
      </div>
    );
  }

  renderWords(word, singular, dual, plural) {
    if (!word) return <div></div>;
    return (
      <div className="col border border-secondary">
        {this.renderWord("Singular", word[singular] && word[singular].text)}
        {this.renderWord("Dual", word[dual] && word[dual].text)}
        {this.renderWord("Plural", word[plural] && word[plural].text)}
      </div>
    );
  }

  renderWord(title, word) {
    return (
      <div>
        <div className="row" style={{ fontSize: "14px", paddingLeft: "5px" }}>
          {title}
        </div>
        <div
          className="row"
          style={{
            fontSize: "12px",
            paddingLeft: "10px",
            paddingBottom: "15px",
          }}
        >
          {word}
        </div>
      </div>
    );
  }
}

export default BaseVerbRow;
