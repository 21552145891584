import React from "react";
import BaseModal from "../../common/BaseModal";
import InputFileButtonPrimary from "../../common/InputFileButtonPrimary";
import Modal2Button from "../../common/Modal2Button";
import Joi from "joi-browser";
import Progressbar from "../../common/Progressbar";
import Music from "../../common/Music";
import MusicDownload from "../../common/MusicDownload";
class ExampleEdit extends BaseModal {
  state = {
    data: {
      id: "",
      rowVersion: "",
      sekaniExample: "",
      englishMeaning: "",
      fileVoice: null,
      voice: null,
    },
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    sekaniExample: Joi.string().required().label("sekani example"),
    englishMeaning: Joi.string().required().label("english meaning"),
    voice: Joi.any().label("voice"),
    fileVoice: Joi.any(),
    rowVersion: Joi.any(),
    id: Joi.any(),
    index: Joi.any(),
    wordId: Joi.any(),
    audioFileName: Joi.any(),
  };

  componentDidMount = async () => {
    const { data } = this.props;
    if (data) {
      if (data != null) {
        this.setState({
          data: data,
        });
      }
    }
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Edit Example", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  renderBody() {
    return (
      <div>
        <div className="row">
          <div className="col-10" style={{ marginTop: "80px" }}>
            {this.renderTextArea("sekaniExample", "Sekani example")}
          </div>
        </div>

        <div className="row">
          <div className="col-10" style={{ marginTop: "80px" }}>
            {this.renderTextArea("englishMeaning", "English meaning")}
          </div>
        </div>

        <div>
          <div style={{ margin: "auto" }}>
            <InputFileButtonPrimary
              accept="audio/*"
              text="Upload Audio"
              onChange={this.handelChooseFile}
            />

            {this.state.data.fileVoice ? (
              <Music
                isEnable={this.state.data.fileVoice === null}
                audio={this.state.data.fileVoice}
              />
            ) : (
              this.state.data.audioFileName && (
                <MusicDownload url={this.state.data.audioFileName} />
              )
            )}
          </div>
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal2Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
            onChooseFile={this.handelChooseFile}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  handelPlay = () => {};

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };
    if (data.voice === null) {
      return;
    }
    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  handelChooseFile = async (event) => {
    var voice = event.target.files[0];
    this.setState({
      data: {
        ...this.state.data,
        voice,
        fileVoice: URL.createObjectURL(voice),
      },
    });
  };

  clearData() {
    this.setState({
      data: {
        name: "",
        voice: null,
        fileVoice: null,
      },
    });
  }
}

export default ExampleEdit;
