import BaseService from "../../../services/BaseService";
const endpoint = "/WordAudio";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {

  Create(rootId, Id, audioFile) {
    let body = {
      rootId: rootId,
      Id: Id,
      audioFile: audioFile
    };
    return BaseService.CreateMultipart(endpoint, body, multipartHeader);
  },
  Delete(rootId, id) {
    return BaseService.Delete(endpoint + `/${rootId}/${id}`);
  },

};
