import axios from "axios";
import logger from "./LogService";
import { toast } from "react-toastify";
import auth from "../services/AuthService";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.responsec &&
    error.response.status >= 400 &&
    error.response.status < 500;
  console.log(error);
  //logger.log(error);
  if (!expectedError) {
    let message = error.response.data.Message
      ? error.response.data.Message
      : error.response.data.title
      ? error.response.data.title
      : "An unexpected error occurred. " + error.message;
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  //TODO : error this uncomment this code
  //return Promise.reject(error);
});

axios.interceptors.request.use(function (config) {
  var currentUser = auth.getJwt();
  if (currentUser) {
    config.headers["Authorization"] = `Bearer ${JSON.parse(currentUser).token}`;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Allow"] = "*/*";
  config.headers["Access-Control-Allow-Credentials"] = "true";
  config.headers["Access-Control-Allow-Methods"] = "*";
  //config.headers["Accept"] = "application/json";
  //config.headers["Content-Type"] = "multipart/form-data";
  // config.headers["Cache-Control"] = "no-cache";
  //config.headers["Host"] = "208.85.1.122";
  config.crossDomain = true;
  config.accept = "*/*";

  return config;
});

export function setJwt(jwt) {
  //axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
