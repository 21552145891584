import React, { Component } from "react";
import CuredButtonBar from "./CuredButtonBar";
class NavigationCuredButton extends Component {
  render() {
    const { items } = this.props;
    let size = items.length;
    return (
      <div className="center">
        <div className="row" style={{ marginLeft: 15, marginRight: 15 }}>
          {items.map((item, index) => this.renderButton(item, index, size))}
        </div>
      </div>
    );
  }

  renderButton(item, index, size) {
    const { onClick } = this.props;

    let position = "middle";
    if (index === 0) position = "start";
    if (index > 0 && index < size - 1) position = "middle";
    if (index === size - 1) position = "end";
    return (
      <CuredButtonBar
        key={item.text}
        disabled={item.disabled}
        position={position}
        text={item.text}
        onClick={() => onClick(item)}
      />
    );
  }
}

export default NavigationCuredButton;
