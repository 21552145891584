import React, { Component } from "react";
import Grid from "../../common/Grid";
import ButtonPrimary from "../../common/ButtonPrimary";
import Card from "./../../common/Card";

class TopicGrid extends Component {
  card = {
    content: (item) => (
      <Card
        title={item.name}
        body={this.renderBody(item)}
        bottom={this.renderBottom(item)}
      />
    ),
  };

  renderBottom(item) {
    return (
      <div className="row card-item-bottom">
        <div style={{ margin: "auto" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div>
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      </div>
    );
  }
  renderBody(item) {
    return (
      <React.Fragment>
        <div style={{ overflow: "hidden", height: "190px" }} className="col">
          {item.description}
        </div>
        <div className="col img-center">
          <img className="img-fluid " src={item.imageUrl} alt="Level" />
        </div>
      </React.Fragment>
    );
  }

  columns = [
    {
      path: "name",
      lable: "Name",
      headerStyle: { width: "25%", paddingLeft: "25px" },
      content: (item) => (
        <div
          className="table-col"
          style={{
            width: "25%",
            paddingLeft: "25px",
          }}
        >
          {item.name}
        </div>
      ),
      key: "name",
    },
    {
      path: "description",
      lable: "Description",
      headerStyle: {
        width: "25%",
        paddingLeft: "25px",
      },
      content: (item) => (
        <div
          className="table-col"
          style={{ width: "50%", paddingLeft: "25px" }}
        >
          {item.description}
        </div>
      ),
      key: "description",
    },
    {
      path: "edit",
      lable: "",
      key: "edit",
      headerStyle: { width: "12.5%" },
      content: (item) => (
        <div className="table-col" style={{ width: "12.5%" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div>
      ),
    },
    {
      path: "delete",
      lable: "",
      key: "delete",
      headerStyle: { width: "12.5%" },
      content: (item) => (
        <div className="table-col" style={{ width: "12.5%" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      ),
    },
  ];
  onSort(sortColumn) {
    this.props.onSort(sortColumn);
  }
  state = {};
  render() {
    const { items, onFetchMore, isLoading, pageSize, skipPages } = this.props;
    return (
      <Grid
        style={{ margin: 20 }}
        data={items}
        card={this.card}
        skipPages={skipPages}
        pageSize={pageSize}
        onFetchMore={onFetchMore}
        isLoading={isLoading}
      />
    );
  }
}
export default TopicGrid;
