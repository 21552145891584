import React, { Component } from "react";
import Spinner from "../../common/Spinner";
import WordApi from "../word/WordService";
import { WordAttributes } from "../../../domain/Contracts";
class InalienableNounRow extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { id } = this.props.data;
    this.setState({
      rootId: id,
    });
    this.init(id);
  }

  init = async (rootId) => {
    await this.setLoading(true);
    var data = [...(await WordApi.Get(rootId)).data];
    this.initWords(data);
    await this.setLoading(false);
  };

  initWords = async (data) => {
    let words = [];
    data.forEach((word) => {
      words[word.attribute] = word;
    });

    this.setState({
      words,
    });
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  render() {
    if (!this.state) return <div></div>;
    return (
      <div>
        <Spinner hidden={this.state.isLoading} />
        <div
          hidden={this.state.isLoading}
          className=" row inalienable-row "
          style={{ padding: "8px" }}
        >
          <div style={{}} className="row w-100">
            {this.renderHeader("First Person", 1)}
            {this.renderHeader("Second Person", 2)}
            {this.renderHeader("Third person", 3)}
            {this.renderHeader("Reflexive", 4)}
            {this.renderHeader("Dijoint", 5)}
          </div>
          <div style={{}} className="row w-100">
            {this.renderPluralWords(
              this.state.words,
              WordAttributes.FirstPersonSingular.id,
              WordAttributes.FirstPersonPlural.id
            )}
            {this.renderPluralWords(
              this.state.words,
              WordAttributes.SecondPersonSingular.id,
              WordAttributes.SecondPersonPlural.id
            )}
            {this.renderPluralWords(
              this.state.words,
              WordAttributes.ThirdPersonSingular.id,
              WordAttributes.ThirdPersonPlural.id
            )}
            {this.renderPluralWords(
              this.state.words,
              WordAttributes.Reflexive.id,
              WordAttributes.PluralReflexive.id
            )}
            {this.renderPluralWords(
              this.state.words,
              WordAttributes.DisjointReference.id,
              WordAttributes.PluralDisjointReference.id
            )}
          </div>
          <div style={{}} className="row w-100">
            {this.renderPerson("Reciprocal", 6)}
            {this.renderPerson("indefinte", 7)}
            {this.renderPerson("", 0)}
            {this.renderPerson("", 0)}
            {this.renderPerson("", 0)}
          </div>
          <div style={{}} className="row w-100">
            {this.renderOneWord(this.state.words, WordAttributes.Reciprocal.id)}
            {this.renderOneWord(this.state.words, WordAttributes.Indefinite.id)}
            {this.renderOneWord(this.state.words, "")}
            {this.renderOneWord(this.state.words, "")}
            {this.renderOneWord(this.state.words, "")}
          </div>
        </div>
      </div>
    );
  }

  renderHeader(verbName, colorIndex) {
    let indexColorClasss = "dot-circle p" + colorIndex + "-color";
    return (
      <div className="col border border-secondary">
        <div className="row" style={{ padding: "5px" }}>
          <div className={indexColorClasss} />
          <div className="col"> {verbName}</div>
        </div>
      </div>
    );
  }

  renderPerson(personTitle, colorIndex) {
    let indexColorClasss = "dot-circle p" + colorIndex + "-color";
    return (
      <div className="col border border-secondary">
        <div className="row" style={{ padding: "5px" }}>
          <div className={indexColorClasss} />
          <div className="col">{personTitle}</div>
        </div>
      </div>
    );
  }

  renderWords(word, singular, dual) {
    if (!word) return <div></div>;
    return (
      <div className="col border border-secondary">
        {this.renderWord("Singular", word[singular] && word[singular].text)}
        {this.renderWord("Dual", word[dual] && word[dual].text)}
      </div>
    );
  }

  renderPluralWords(word, singular, Plural) {
    if (!word) return <div></div>;
    return (
      <div className="col border border-secondary">
        {this.renderWord("Singular", word[singular] && word[singular].text)}
        {this.renderWord("Plural", word[Plural] && word[Plural].text)}
      </div>
    );
  }

  renderOneWord(word, first) {
    if (!word) return <div></div>;
    return (
      <div className="col border border-secondary">
        {this.renderWord(" ", word[first] && word[first].text)}
      </div>
    );
  }

  renderWord(title, word) {
    return (
      <div>
        <div className="row" style={{ fontSize: "14px", paddingLeft: "5px" }}>
          {title}
        </div>
        <div
          className="row"
          style={{
            fontSize: "12px",
            paddingLeft: "10px",
            paddingBottom: "15px",
          }}
        >
          {word}
        </div>
      </div>
    );
  }
}

export default InalienableNounRow;
