import React from "react";
const Collapse = ({ isCollapsed = true, children, className }) => {
  return (
    <React.Fragment>
      <div
        className={`collapse-content ${
          isCollapsed ? "collapsed" : "expanded"
        } ${className}`}
        aria-expanded={isCollapsed}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

export default Collapse;
