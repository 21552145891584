import React from "react";
import BaseModal from "../../common/BaseModal";
import Music from "../../common/Music";
import Modal3Button from "../../common/Modal3Button";
import InputFileButtonPrimary from "../../common/InputFileButtonPrimary";
import Progressbar from "../../common/Progressbar";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import NoImageIcon from "../../../images/no_image_rounded.svg";

class StoryPageCreate extends BaseModal {
  imageNotSelectMessage = "Please select a photo for story";
  state = {
    data: {
      Number: 1,
      SekaniStory: "",
      EnglishStory: "",
      image: null,
      englishAudio: null,
      sekaniAudio: null,
    },
    englishAudio: null,
    sekaniAudio: null,
    image: null,
    errors: {},
    progressbarIsShow: false,
  };

  schema = {
    Number: Joi.number().required().label("Number of page"),
    SekaniStory: Joi.string().required().label("Sekani Story"),
    EnglishStory: Joi.string().required().label("English Story"),
    image: Joi.label("Page image"),
    englishAudio: Joi.any(),
    sekaniAudio: Joi.any(),
  };

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <React.Fragment>
        {this.renderModal("Add page", onClose, isOpen, this.renderBody())}
      </React.Fragment>
    );
  }

  onEnglishVoice = () => {};

  renderBody() {
    return (
      <div>
        <div className="row">
          <div className="col-7">
            <div className="col-12" style={{ marginTop: "10px" }}>
              {this.renderTextArea("SekaniStory", "Story Text Sekani")}
            </div>
            <div
              className="row"
              style={{ width: "fit-content", paddingLeft: "inherit" }}
            >
              <div style={{ margin: "auto" }}>
                <InputFileButtonPrimary
                  accept="audio/*"
                  text="Upload Audio"
                  onChange={this.handelSekaniChooseFile}
                />
              </div>

              <Music
                isEnable={this.state.sekaniAudio === null}
                audio={this.state.sekaniAudio}
              />
            </div>
            <div className="col-12" style={{ marginTop: "10px" }}>
              {this.renderTextArea("EnglishStory", "Story Text English")}
            </div>

            <div
              className="row"
              style={{ width: "fit-content", paddingLeft: "inherit" }}
            >
              <div style={{ margin: "auto" }}>
                <InputFileButtonPrimary
                  accept="audio/*"
                  text="Upload Audio"
                  onChange={this.handelEnglishChooseFile}
                />
              </div>

              <Music
                isEnable={this.state.englishAudio === null}
                audio={this.state.englishAudio}
              />
            </div>
          </div>
          <div className="col-5 img-center">
            {this.renderImageRounded(
              "image",
              this.state.image,
              NoImageIcon,
              "Please select a photo for page"
            )}
          </div>
        </div>
        <div className="" style={{ marginBottom: "10px" }}>
          <Modal3Button
            disabled={this.isValidate()}
            onNegative={this.handelNegative}
            onPositive={this.handelPositive}
            onChooseFile={this.handelChooseFile}
          />
        </div>

        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  handelNegative = async () => {
    const { onNegative } = this.props;
    this.clearData();
    onNegative();
  };

  handelPositive = async () => {
    let data = { ...this.state.data };
    if (data.image === null) {
      this.toastImageMessage();
      return;
    }
    if (data.englishAudio === null) {
      this.toastAudioMessage("Sekani audio");
      return;
    }
    if (data.sekaniAudio === null) {
      this.toastAudioessage("Sekani audio");
      return;
    }

    await this.setProgressbarVisibility(true);
    const { onPositive } = this.props;

    await this.clearData();
    await onPositive(data);
    await this.setProgressbarVisibility(false);
  };

  setProgressbarVisibility = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };

  toastImageMessage() {
    toast.error("Please select a photo for story", {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  toastAudioMessage(message) {
    toast.error(`Please select a ${message} for story`, {
      position: toast.POSITION.TOP_CENTER,
      className: "input-error",
      bodyClassName: "input-error",
      autoClose: true,
      closeButton: false,
      toastId: "unexpectedError",
    });
  }

  handelEnglishChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        englishAudio: event.target.files[0],
      },
      englishAudio: URL.createObjectURL(event.target.files[0]),
    });
  };

  handelSekaniChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        sekaniAudio: event.target.files[0],
      },
      sekaniAudio: URL.createObjectURL(event.target.files[0]),
    });
  };

  handelChooseFile = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        image: event.target.files[0],
      },
      image: URL.createObjectURL(event.target.files[0]),
    });
  };

  clearData = async () => {
    this.setState({
      data: {
        name: "",
        number: 0,
        description: "",
        sekaniAudio: null,
        englishAudio: null,
      },
      sekaniAudio: null,
      englishAudio: null,
    });
  };
}

export default StoryPageCreate;
