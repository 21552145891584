import React from "react";
import ArrowRightIcon from "./../../../images/arrow_right.svg";
import ArrowDownIcon from "./../../../images/arrow_down.svg";
const ToggleRootButton = ({
  checked = false,
  width = 16,
  onClick,
  ...rest
}) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      {checked ? (
        <img
          src={ArrowDownIcon}
          {...rest}
          className="img-fluid"
          style={{ width: width }}
        />
      ) : (
        <img
          src={ArrowRightIcon}
          {...rest}
          className="img-fluid"
          style={{ width: width }}
        />
      )}
    </div>
  );
};

export default ToggleRootButton;
