import React from "react";
const ImageRounded = ({ image, noImageIcon, width = 200, error, ...rest }) => {
  return (
    <React.Fragment>
      {image === null ? (
        <img
          {...rest}
          className="rounded-circle"
          style={{ width: width,height:width,borderRadius: "50%", }}
          src={noImageIcon}
        />
      ) : (
        <img
          {...rest}
          className="rounded-circle"
          style={{ width: width,height:width,borderRadius: "50%", }}
          src={image}
        />
      )}

      {image === null && (
        <div className="input-error ">
          <label>{error}</label>
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageRounded;
