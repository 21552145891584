import React from "react";
import queryString from "query-string";
const Post = ({ match, location }) => {
  const { sortBy } = queryString.parse(location.search);
  return (
    <h1>
      Id: {match.params.id} -- postId:{match.params.postId} sortBy:{sortBy}
    </h1>
  );
};

export default Post;
