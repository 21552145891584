import React from "react";
import SelectTag from "react-select";
import _ from "lodash";

const Select = ({ name, label, options, error, value, onChange, ...rest }) => {
  var defaultValue = _.find(options, function (o) {
    return o._id === value;
  });

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>

      <SelectTag
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option._id}
        options={options}
        value={defaultValue}
        name={name}
        id={name}
        onChange={(value) => onChange(name, value)}
        defaultValue={defaultValue}
      />

      {error && (
        <div className="input-error ">
          <label>{error}</label>
        </div>
      )}
    </div>
  );
};

export default Select;
