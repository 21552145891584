import React from "react";
import { NavLink } from "react-router-dom";

const IconButton = ({ icon, label, to }) => {
  return (
    <NavLink className="nav-item" to={to}>
      <img
        src={icon}
        alt="Icon"
        className="icon-input-left header-button-icon"
      ></img>
      <label className="header-button-label">{label}</label>
    </NavLink>
  );
};

export default IconButton;
