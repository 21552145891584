import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
class Grid extends Component {
  render() {
    const {
      data,
      card,
      onFetchMore,
      isLoading,
      pageSize,
      skipPages,
      ...props
    } = this.props;
    return (
      <div {...props}>
        <div className="row">
          {data.map((item, index) => (
            <div key={item.id}>
              {card.content(item)}
              {index === data.length - pageSize && (
                <Waypoint onEnter={onFetchMore} />
              )}
            </div>
          ))}
        </div>
        {isLoading && (
          <div className="alert alert-light" role="alert">
            <div className="d-flex justify-content-center">
              <button className="btn btn-dark" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Grid;
