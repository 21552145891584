import React, { Component } from "react";
import ButtonPrimary from "../../common/ButtonPrimary";
import Grid from "../../common/Grid";
import Card from "./../../common/Card";
class ElderGrid extends Component {
  card = {
    content: (item) => (
      <Card
        title={item.name}
        body={this.renderBody(item)}
        bottom={this.renderBottom(item)}
      />
    ),
  };

  renderBottom(item) {
    return (
      <div className="row card-item-bottom" style={{ width: "auto" }}>
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            text="Stories"
            onClick={() => this.props.onStories(item)}
          />
        </div>
        <div style={{ margin: "auto", padding: "5px" }}></div>
        <div style={{ margin: "auto" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div>
        <div style={{ margin: "auto", padding: "5px" }}></div>
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      </div>
    );
  }
  renderBody(item) {
    return (
      <React.Fragment>
        <div className="col">{item.description}</div>
        <div className="col img-center">
          <img
            className="rounded-circle"
            style={{ width: 150, height: 150 }}
            src={item.imageUrl}
            alt="Elder"
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { items, onFetchMore, isLoading, pageSize, skipPages } = this.props;
    return (
      <Grid
        style={{ margin: 20 }}
        data={items}
        card={this.card}
        skipPages={skipPages}
        pageSize={pageSize}
        onFetchMore={onFetchMore}
        isLoading={isLoading}
      />
    );
  }
}
export default ElderGrid;
