import React, { Component } from "react";
import MainLayout from "../../layout/MainLayout";
import ActionBar from "../../common/ActionBar";
import ButtonPrimary from "../../common/ButtonPrimary";
import LevelIcon from "./../../../images/level.svg";
import YesNoPrompt from "../../common/YesNoDialog";
import api from "./UserService";
import UserGrid from "./UserGrid";
import UserCreate from "./UserCreate";
import _ from "lodash";
import axios from "axios";
class User extends Component {
  state = {
    pageSize: 10,
    searchText: "",
    skipPages: 0,
    data: [],
    sortColumn: { path: "lastName", order: "asc" },
    isLoading: false,
    modalIsDeleteOpen: false,
    modalIsUserCreateOpen: false,
    modalIsAdminCreateOpen: false,
    selectedForDelete: {},
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
    this.init();
  };

  token = null;
  componentDidMount() {
    const CancelToken = axios.CancelToken;
    this.token = CancelToken.source();
    this.init();
  }
  init = async () => {
    this.setLoading(true);

    await this.setState({
      skipPages: 0,
      data: [],
    });
    this.debouncedSearch();
    this.setLoading(false);
  };

  fetch = async () => {
    this.setLoading(true);
    const { searchText, skipPages, sortColumn } = this.state;

    var newData = await (
      await api.Get(searchText, skipPages, sortColumn, this.token)
    ).data;
    var data = [...this.state.data, ...newData];
    this.setState({
      data,
    });
    this.setLoading(false);
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.token.cancel("User search again");
    this.debouncedSearch();
  };

  debouncedSearch = _.debounce(function () {
    this.fetch();
  }, 900);

  handelAddUser = () => {
    this.setState({ modalIsUserCreateOpen: true });
  };

  handelAddAdmin = () => {
    this.setState({ modalIsAdminCreateOpen: true });
  };

  handelDelete = (data) => {
    this.setState({ modalIsDeleteOpen: true, selectedForDelete: data });
  };

  handelFetchMore = async () => {
    const { skipPages } = this.state;
    await this.setState({
      skipPages: skipPages + 1,
    });
    this.fetch();
  };

  handelYesDelete = async (item) => {
    await api.Delete(item.id);
    this.setState({ modalIsDeleteOpen: false });
    this.init();
  };

  handelNoDelete = () => {
    this.setState({ modalIsDeleteOpen: false });
  };

  handelCreateSubmitUser = async (data) => {
    await api.CreateUser(data);
    await api.UploadImage(data);
    this.handelCreateCancelUser();
    //this.init();
  };

  handelCreateSubmitAdmin = async (data) => {
    await api.CreateAdmin(data);
    await api.UploadImage(data);
    this.handelCreateCancelAdmin();
    // this.init();
  };
  handelCreateCancelUser = () => {
    this.setState({ modalIsUserCreateOpen: false });
  };
  handelCreateCancelAdmin = () => {
    this.setState({ modalIsAdminCreateOpen: false });
  };
  handelActiveDeative = async (item) => {
    this.setLoading(true);
    await api.ActiveDeactive(item.email);
    this.setLoading(false);
    this.init();
  };

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }

  renderMainLayoutContent() {
    const {
      data,
      isLoading,
      modalIsDeleteOpen,
      modalIsUserCreateOpen,
      modalIsAdminCreateOpen,
      selectedForDelete,
    } = this.state;

    return (
      <div>
        <div>
          <ActionBar
            icon={LevelIcon}
            addDisabled={true}
            title="Users"
            onSearch={this.handleSearch}
          />
        </div>
        <div className="col">
          <ButtonPrimary
            style={{ marginLeft: 10 }}
            text="Add User"
            onClick={() => this.handelAddUser()}
          />
          <ButtonPrimary
            style={{ marginLeft: 10 }}
            text="Add Admin"
            onClick={() => this.handelAddAdmin()}
          />
        </div>
        <UserGrid
          items={data}
          onEdit={this.handelEdit}
          onDelete={this.handelDelete}
          isLoading={isLoading}
          skipPages={this.state.skipPages}
          pageSize={this.state.pageSize}
          onFetchMore={this.handelFetchMore}
          onActiveDeative={this.handelActiveDeative}
        />
        <YesNoPrompt
          isOpen={modalIsDeleteOpen}
          title="Are you sure?"
          data={selectedForDelete}
          onPositive={this.handelYesDelete}
          onNegative={this.handelNoDelete}
        />

        <div>
          <UserCreate
            key={modalIsUserCreateOpen + "user"}
            type="user"
            isOpen={modalIsUserCreateOpen}
            onPositive={this.handelCreateSubmitUser}
            onNegative={this.handelCreateCancelUser}
            onClose={this.handelCreateCancelUser}
          />
        </div>

        <UserCreate
          key={modalIsAdminCreateOpen + "admin"}
          type="admin user"
          isOpen={modalIsAdminCreateOpen}
          onPositive={this.handelCreateSubmitAdmin}
          onNegative={this.handelCreateCancelAdmin}
          onClose={this.handelCreateCancelAdmin}
        />
      </div>
    );
  }
}

export default User;
