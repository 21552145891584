import React, { Component } from "react";
import Pagination from "../../common/Pagination";
import { paginate } from "../../../Utils/Paginate";
import _ from "lodash";
import TestTable from "../../common/Table";
import ListGroup from "../../common/ListGroup";
import NavigationBar from "../../layout/NavigationBar";

class TestPage extends Component {
  state = {
    pageSize: 10,
    currentPage: 1,
    sortColumn: { path: "name", order: "asc" },
    currentGroup: null,
    allItem: [],
    groups: [],
  };
  componentDidMount() {
    let groups = _.range(1, 10, 1).map((item) => {
      return { _id: item, name: "Name " + item };
    });
    groups = [{ _id: "", name: "All Items" }, ...groups];

    let allItem = _.range(1, 100, 1).map((item) => {
      return { id: item, name: "Name " + item };
    });
    this.setState({
      allItem,
      groups,
    });
  }

  handleChangePage = (page) => {
    this.setState({ currentPage: page });
  };
  handleGroupSelect = (group) => {
    this.setState({ currentGroup: group, currentPage: 1 });
  };

  handleItemSelected = (item) => {
    console.log(item);
  };
  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getPageData() {
    const {
      pageSize,
      currentPage: selectedPage,
      sortColumn,
      allItem,
      currentGroup: selectedGroup,
    } = this.state;
    const filterd =
      selectedGroup && selectedGroup["_id"]
        ? allItem.filter((item) => {
            console.log(item === selectedGroup["_id"]);
            return item % selectedGroup["_id"] === 0;
          })
        : allItem;
    const orderd = _.orderBy(filterd, sortColumn.path, sortColumn.order);
    const items = paginate(orderd, selectedPage, pageSize);
    console.log(filterd);
    return { totalCount: filterd.length, data: items };
  }

  render() {
    const {
      pageSize,
      currentPage: selectedPage,
      sortColumn,
      groups,
      currentGroup: selectedGroup,
    } = this.state;

    const { totalCount, data } = this.getPageData();
    return (
      <div className="row">
        <div className="col-md-2" style={{ minWidth: 250, maxWidth: 250 }}>
          <NavigationBar />
        </div>
        <div className="col-3">
          <ListGroup
            items={groups}
            onItemSelected={this.handleGroupSelect}
            selectedItem={selectedGroup}
          />
        </div>
        <div className="col">
          <TestTable
            items={data}
            onSelected={this.handleItemSelected}
            sortColumn={sortColumn}
            onSort={this.handleSort}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            onPageChange={this.handleChangePage}
            selectedItem={selectedPage}
          ></Pagination>
        </div>
      </div>
    );
  }
}

export default TestPage;
