import React from "react";
import { NavLink } from "react-router-dom";

const NavButton = ({ icon, label, to }) => {
  return (
    <div className="background-nav-button">
      <NavLink className="nav-item nav-link" to={to}>
        <img
          src={icon}
          alt="Icon"
          className="icon-input-left nav-button-icon"
        ></img>
        <label className="nav-button-label">{label}</label>
      </NavLink>
    </div>
  );
};

export default NavButton;
