import React, { Component } from "react";
import MainLayout from "../../layout/MainLayout";
import ActionBar from "../../common/ActionBar";
import LevelIcon from "./../../../images/level.svg";
import YesNoPrompt from "../../common/YesNoDialog";
import api from "../stories/StoriesService";
import StoryGrid from "./StoryPageGrid";
import StoryCreate from "./StoryPageCreate";
import StoryEdit from "./StoryPageEdit";
import queryString from "query-string";
import _ from "lodash";
class Story extends Component {
  state = {
    pageSize: 10,
    searchText: "",
    order: { path: "number", order: "desc" },
    skipPages: 0,
    data: [],
    isLoading: false,
    modalIsDeleteOpen: false,
    modalIsCreateOpen: false,
    modalIsEditOpen: false,
    selectedForDelete: {},
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  componentDidMount = async () => {
    let params = queryString.parse(this.props.location.search);
    let storyId = params.storyId;
    const storyName = params.storyName ? params.storyName : "";
    await this.setState({
      storyId,
      storyName,
    });
    this.fetch();
  };

  init = async () => {
    this.setLoading(true);

    await this.setState({
      skipPages: 0,
      data: [],
    });
    this.debouncedSearch();
    this.setLoading(false);
  };

  fetch = async () => {
    this.setLoading(true);
    const { order } = this.state;
    var data = [...(await api.GetStoryPages(this.state.storyId, order)).data];
    this.setState({
      data,
    });
    this.setLoading(false);
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.debouncedSearch();
  };

  debouncedSearch = _.debounce(function () {
    this.fetch();
  }, 700);

  handelAdd = () => {
    this.setState({ modalIsCreateOpen: true });
  };

  handelEdit = (data) => {
    this.setState({ modalIsEditOpen: true, selectedForEdit: data });
  };

  handelPage = (data) => {
    this.props.history.push({
      pathname: "/StoryPages",
      elderId: data.id,
    });
  };

  handelDelete = (data) => {
    this.setState({ modalIsDeleteOpen: true, selectedForDelete: data });
  };
  handelFetchMore = async () => {
    const { skipPages } = this.state;
    await this.setState({
      skipPages: skipPages + 1,
    });
    this.fetch();
  };

  handelYesDelete = async (item) => {
    console.log(item);
    await api.DeleteStoryPage(item.storyId, item.id);
    this.setState({ modalIsDeleteOpen: false });
    this.init();
  };

  handelNoDelete = () => {
    this.setState({ modalIsDeleteOpen: false });
  };

  handelCreateSubmit = async (data) => {
    data.StoryId = this.state.storyId;
    await api.CreateStoryPage(data);
    this.setState({ modalIsCreateOpen: false });
    this.handelCreateCancel();
    this.init();
  };
  handelCreateCancel = () => {
    this.setState({ modalIsCreateOpen: false });
  };

  handelEditSubmit = async (data) => {
    await api.UpdateStoryPage(data);
    this.handelEditCancel();
    this.setState({ selectedForEdit: null });
    this.init();
  };

  handelEditCancel = () => {
    this.setState({ modalIsEditOpen: false });
    this.setState({ selectedForEdit: null });
  };

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }

  renderMainLayoutContent() {
    const {
      data,
      isLoading,
      modalIsDeleteOpen,
      modalIsCreateOpen,
      modalIsEditOpen,
      elderName,
      selectedForDelete,
      selectedForEdit,
    } = this.state;
    let title = elderName ? `Pages of ${elderName}` : "";
    return (
      <div>
        <div>
          <ActionBar
            icon={LevelIcon}
            title={title}
            onAdd={this.handelAdd}
            // onSearch={this.handleSearch}
          />
        </div>
        <StoryGrid
          items={data}
          onEdit={this.handelEdit}
          onDelete={this.handelDelete}
          isLoading={isLoading}
          skipPages={this.state.skipPages}
          pageSize={this.state.pageSize}
          onStories={this.handelStories}
          onFetchMore={this.handelFetchMore}
        />
        <YesNoPrompt
          isOpen={modalIsDeleteOpen}
          title="Are you sure?"
          data={selectedForDelete}
          onPositive={this.handelYesDelete}
          onNegative={this.handelNoDelete}
        />

        <StoryCreate
          key={"Add_" + modalIsCreateOpen}
          isOpen={modalIsCreateOpen}
          onPositive={this.handelCreateSubmit}
          onNegative={this.handelCreateCancel}
          onClose={this.handelCreateCancel}
        />

        <StoryEdit
          key={selectedForEdit && selectedForEdit.id}
          isOpen={modalIsEditOpen}
          data={selectedForEdit}
          onPositive={this.handelEditSubmit}
          onNegative={this.handelEditCancel}
          onClose={this.handelEditCancel}
        />
      </div>
    );
  }
}

export default Story;
