import React from "react";
import CuredButton from "./CuredButton";
const CuredButtonBar = ({ text, onClick, disabled, position, ...props }) => {
  let className = `bar-blue-${position}`;
  return (
    <div className={className}>
      <CuredButton text={text} disabled={disabled} onClick={onClick} />
    </div>
  );
};

export default CuredButtonBar;
