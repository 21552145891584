import React, { Component } from "react";
import sort from "./../../images/sort_solid.svg";
import sortUp from "./../../images/sort_up.svg";
import sortDown from "./../../images/sort_down.svg";
class TableHeader extends Component {
  state = {};
  onSort(path) {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  }

  renderSortIcon = (column) => {
    const { sortColumn } = this.props;
    if (!column.path) return null;
    if (column.path !== sortColumn.path)
      return <img style={{ width: 12 }} src={sort} alt="Icon"></img>;
    if (sortColumn.order === "asc")
      return <img style={{ width: 12 }} src={sortUp} alt="Icon"></img>;
    return <img style={{ width: 12 }} src={sortDown} alt="Icon"></img>;
  };

  renderHeader(column) {
    var className = "";
    if (column.path) {
      className = `${className} clickable`;
    }

    return (
      <div
        key={column.path || column.key}
        onClick={() => {
          if (column.path) {
            this.onSort(column.path);
          }
        }}
        style={column.headerStyle}
        className={className}
      >
        {column.lable}
        {this.renderSortIcon(column)}
      </div>
    );
  }
  render() {
    return (
      <div className="row h-100">
        {this.props.columns.map((column) => this.renderHeader(column))}
      </div>
    );
  }
}

export default TableHeader;
