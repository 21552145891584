import React from "react";
import Close from "../../images/modal_white_close.svg";
const ItemDeleteable = ({ title, onRemove, ...rest }) => {
    return (
        <div {...rest} className="alert alert-info" style={{ height: "fit-content", margin: "3px" }} >
            {title}  {"   "}
            <img
                src={Close}
                onClick={onRemove}
                alt="Close Icon"
            />
        </div>
    );
};

export default ItemDeleteable;
