import React from "react";
import ButtonPrimary from "../common/ButtonPrimary";

const Modal2Button = ({ onNegative, onPositive, disabled }) => {
  return (
    <div
      className="row"
      style={{ paddingTop: "5%", width: "30%", margin: "auto" }}
    >
      <div style={{ margin: "auto" }}>
        <ButtonPrimary
          disabled={disabled}
          text="Save"
          onClick={() => onPositive()}
        />
      </div>

      <div style={{ margin: "auto" }}>
        <ButtonPrimary text="Cancel" onClick={() => onNegative()} />
      </div>
    </div>
  );
};

export default Modal2Button;
