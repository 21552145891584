import React from "react";
import Modal from "react-modal";
import YesIcon from "./../../images/tik_green.svg";
import NoIcon from "./../../images/cancel_red.svg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
  },
};

const YesNoPrompt = ({ title, onPositive, onNegative, isOpen, data }) => {
  return (
    <Modal ariaHideApp={false} style={customStyles} isOpen={isOpen}>
      <div className="modal-background">
        <div className="model-content center">{title}</div>

        <div className="row" style={{ paddingTop: "5%" }}>
          <img
            src={YesIcon}
            onClick={() => onPositive(data)}
            alt="Yes"
            style={{ margin: "auto", paddingLeft: "30%" }}
          />

          <img
            src={NoIcon}
            alt="No"
            onClick={() => onNegative(data)}
            style={{ margin: "auto", paddingRight: "30%" }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default YesNoPrompt;
