import BaseService from "../../../services/BaseService";
const endpoint = "/Api/User";
const endpointAccount = "/Account";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  GetAll(order) {
    return BaseService.GetAll(endpoint, order);
  },
  Get(SearchText, SkipPages, order, token) {
    return BaseService.Get(endpoint, SearchText, SkipPages, order, 30, token);
  },
  CreateUser(data) {
    return BaseService.Create(endpointAccount + "/register", data);
  },
  CreateAdmin(data) {
    return BaseService.Create(endpointAccount + "/registerAdmin", data);
  },
  UploadImage(data) {
    return BaseService.CreateMultipart(
      endpoint + `/SetUserImage?email=${data.email}`,
      data,
      multipartHeader
    );
  },
  ActiveDeactive(data) {
    return BaseService.Create(
      endpointAccount + `/activDeactiveUser?email=${data}`
    );
  },
  Delete(id) {
    return BaseService.Delete(endpoint, id);
  },
  Update(data) {
    return BaseService.UpdateMultipart(endpoint, data, multipartHeader);
  },
};
