import React from "react";
import { NavLink } from "react-router-dom";

const NavHeader = ({ icon, label, to }) => {
  return (
    <React.Fragment>
      <div className="nav-item nav-link " to={to}>
        <span>
          <img
            src={icon}
            alt="Icon"
            className="icon-input-left nav-header-icon"
          ></img>
          <label className="nav-header-label">{label}</label>
        </span>
      </div>
    </React.Fragment>
  );
};

export default NavHeader;
