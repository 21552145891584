import React, { Component } from "react";
import ButtonPrimary from "../../common/ButtonPrimary";
import Grid from "../../common/Grid";
import Card from "./../../common/Card";
class LevelGrid extends Component {
  card = {
    content: (item) => (
      <Card
        title={item.name}
        body={this.renderBody(item)}
        bottom={this.renderBottom(item)}
      />
    ),
  };

  renderBottom(item) {
    return (
      <div className="row card-item-bottom">
        <div style={{ margin: "auto" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div>
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      </div>
    );
  }
  renderBody(item) {
    return (
      <React.Fragment>
        <div style={{ overflow: "hidden", height: "190px" }} className="col">
          Level: {item.number}
          <br />
          {item.description}
        </div>
        <div className="col img-center">
          <img
            style={{ height: "190px" }}
            className="img-fluid "
            src={item.imageUrl}
            alt="Level"
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { items, onFetchMore, isLoading, pageSize, skipPages } = this.props;
    return (
      <Grid
        style={{ margin: 20 }}
        data={items}
        card={this.card}
        skipPages={skipPages}
        pageSize={pageSize}
        onFetchMore={onFetchMore}
        isLoading={isLoading}
      />
    );
  }
}
export default LevelGrid;
