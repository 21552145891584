import React from "react";
import LoginForm from "./../components/pages/LoginForm";
import Level from "./../components/pages/level/Level";
import Topic from "./../components/pages/topic/Topic";
import TestPage from "./../components/pages/TestPage/TestPage";
//import ProdectedRoute from "../components/common/ProdectedRoute";
//import ProdectedLogin from "./ProdectedLogin";
import { Redirect, Switch, Route } from "react-router-dom";
//import AuthApi from "../services/AuthApi";
import Post from "./../components/pages/TestPage/post";
import Category from "./../components/pages/category/Category";
import NotFound from "./../components/pages/notFound";
import InalienableNoun from "../components/pages/word/inalienableNoun/InalienableNoun";
import Generic from "../components/pages/word/generic/Generic";
import BaseVerb from "./../components/pages/word/baseVerb/BaseVerb";
import Root from "./../components/pages/root/Root";
import Logout from "../components/layout/Logout";
import EnglishWord from "../components/pages/englishWords/EnglishWord";
import Elder from "../components/pages/elder/Elder";
import Stories from "../components/pages/stories/Stories";
import StoryPages from "../components/pages/storyPages/StoryPages";
import User from "../components/pages/user/User";
import Profile from "../components/pages/user/Profile";
import ProtectedRoute from "./ProtectedRoute";

const Routes = () => {
  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/Login`}
        path="/Login"
        component={LoginForm}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/ForgotPassword`}
        path="/ForgotPassword"
        component={LoginForm}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/User`}
        path="/User"
        component={User}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Profile`}
        path="/Profile"
        component={Profile}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/InalienableNoun`}
        path="/InalienableNoun"
        component={InalienableNoun}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Generic`}
        path="/Generic"
        component={Generic}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/BaseVerb`}
        path="/BaseVerb"
        component={BaseVerb}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Level`}
        path="/Level"
        component={Level}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/logout`}
        path="/logout"
        component={Logout}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Category`}
        path="/Category"
        component={Category}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Topic`}
        path="/Topic"
        component={Topic}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Root`}
        path="/Root"
        component={Root}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/EnglishWord`}
        path="/EnglishWord"
        component={EnglishWord}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Elders`}
        path="/Elders"
        component={Elder}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/Stories`}
        path="/Stories"
        component={Stories}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/StoryPages`}
        path="/StoryPages"
        component={StoryPages}
      />

      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/not`}
        path="/not-found"
        component={NotFound}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/post/:id?/:postId`}
        path="/post/:id?/:postId?"
        component={Post}
      />
      <ProtectedRoute
        path={`${process.env.PUBLIC_URL}/TestPage`}
        render={(props) => (
          <TestPage sortColumn={{ order: "desc", path: "id" }} {...props} />
        )}
      />

      <Redirect from="*" exact to="/Root" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
