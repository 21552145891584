import React, { Component } from "react";
import ButtonPrimary from "../../common/ButtonPrimary";
import Check from "../../common/Check";
import Grid from "../../common/Grid";
import UserCard from "./UserCard";
import UserDefualtIcon from "../../../images/user_defualt.png";
class UserGrid extends Component {
  card = {
    content: (item) => (
      <UserCard
        title={item.name}
        body={this.renderBody(item)}
        bottom={this.renderBottom(item)}
      />
    ),
  };

  renderBottom(item) {
    return (
      <div className="row card-item-bottom">
        {/* <div style={{ margin: "auto" }}>
          <ButtonPrimary text="Edit" onClick={() => this.props.onEdit(item)} />
        </div> */}
        <div style={{ margin: "auto" }}>
          <ButtonPrimary
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
        </div>
      </div>
    );
  }
  renderBody(item) {
    return (
      <React.Fragment>
        <div className="row" style={{ height: "55%", padding: "8px" }}>
          <div className="col img-center">
            <img
              className="image-circle"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "150px",
                height: "150px",
                objectFit: "cover",
                borderRadius: "50%",
                background: "#8778C8",
              }}
              src={item.imageUrl ? item.imageUrl : UserDefualtIcon}
              alt="User Image"
            />
          </div>
        </div>
        <div className="img-center ">
          <div style={{ overflow: "hidden" }} className="col user-card-text">
            {item.email}
          </div>

          <div style={{ overflow: "hidden" }} className="col user-card-text">
            Name: {item.firstName}
          </div>

          <div style={{ overflow: "hidden" }} className="col user-card-text">
            Phone: {item.lastName}
          </div>

          <div style={{ overflow: "hidden" }} className="col user-card-text">
            Active:{" "}
            <Check
              checked={item.isActive}
              disable={false}
              onClick={() => this.props.onActiveDeative(item)}
            />
          </div>

          <div style={{ overflow: "hidden" }} className="col user-card-text">
            Verified: <Check checked={item.emailIsConfirmed} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { items, onFetchMore, isLoading, pageSize, skipPages } = this.props;
    return (
      <Grid
        style={{ margin: 20 }}
        data={items}
        card={this.card}
        skipPages={skipPages}
        pageSize={pageSize}
        onFetchMore={onFetchMore}
        isLoading={isLoading}
      />
    );
  }
}
export default UserGrid;
