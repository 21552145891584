import React from "react";

import NavButton from "../common/NavButton";
import NavHeader from "../common/NavHeader";
import GeneralIcon from "../../images/general.svg";
import FormsIcon from "../../images/forms.svg";
import LevelIcon from "../../images/level.svg";
import RootsIcon from "../../images/roots.svg";
import TopicsIcon from "../../images/topics.svg";
import EnglishWordIcon from "../../images/english_words.svg";
import CategoriesIcon from "../../images/categories.svg";
import UserIcon from "../../images/user_header.svg";
import StoryIcon from "../../images/story.svg";

const NavigationBar = () => {
  return (
    <div className="background-nav ">
      <NavHeader icon={GeneralIcon} label="General" to="/" />
      <NavButton icon={LevelIcon} label="Level" to="/level" />
      <NavButton icon={CategoriesIcon} label="Category" to="/category" />
      <NavButton icon={RootsIcon} label="Root" to="/root" />
      {/* <NavButton icon={FormsIcon} label="Form" to="/form" /> */}
      <NavButton icon={TopicsIcon} label="Topic" to="/topic" />
      <NavButton
        icon={EnglishWordIcon}
        label="English words"
        to="/englishWord"
      />
      <NavHeader icon={UserIcon} label="User" to="/" />
      <NavButton icon={TopicsIcon} label="User list" to="/User" />
      {/* <NavButton icon={TopicsIcon} label="My profile" to="/Profile" /> */}

      <NavHeader icon={StoryIcon} label="Stories" to="/" />
      <NavButton icon={TopicsIcon} label="Elders" to="/elders" />
      <NavButton icon={TopicsIcon} label="Stories" to="/stories" />
    </div>
  );
};

export default NavigationBar;
