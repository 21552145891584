import React from "react";
import TikImage from "../../images/tik.svg";
const RememberMe = ({ checked, onRememberMe }) => {
  console.log(checked);
  return (
    <label className="row">
      <span
        style={{ width: 30, height: 30, marginRight: 4, cursor: "pointer" }}
        className="background-checkbox"
        onClick={() => {
          onRememberMe(!checked);
        }}
      >
        {checked ? (
          <img style={{ padding: 2 }} src={TikImage} alt="checkBox"></img>
        ) : (
          ""
        )}
      </span>
      Remember me
    </label>
  );
};

export default RememberMe;
