import BaseService from "../../../services/BaseService";
const endpoint = "/Story";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  GetAll(order) {
    return BaseService.GetAll(endpoint, order);
  },
  Get(SearchText, SkipPages, order, token) {
    return BaseService.Get(endpoint, SearchText, SkipPages, order, 30, token);
  },

  Create(data) {
    return BaseService.CreateMultipart(endpoint, data, multipartHeader);
  },

  Delete(id) {
    return BaseService.DeleteCustom(`${endpoint}/id?id=${id}`);
  },
  Update(data) {
    return BaseService.UpdateMultipart(endpoint, data, multipartHeader);
  },

  GetByElder(id, order) {
    return BaseService.GetAll(
      `${endpoint}/GetElderStories/elderId?elderId=${id}`,
      order
    );
  },

  GetStoryPages(id, order) {
    return BaseService.GetAll(`${endpoint}/storyId?storyId=${id}`, order);
  },
  CreateStoryPage(data) {
    return BaseService.CreateMultipart(
      `${endpoint}/StoryId`,
      data,
      multipartHeader
    );
  },
  UpdateStoryPage(data) {
    return BaseService.UpdateMultipart(
      `${endpoint}/StoryId`,
      data,
      multipartHeader
    );
  },
  DeleteStoryPage(storyId, id) {
    return BaseService.DeleteCustom(
      `${endpoint}/storyId/pageId?storyId=${storyId}&pageId=${id}`
    );
  },
};
