import React, { Component } from "react";
import MainLayout from "../../layout/MainLayout";
import ActionBar from "../../common/ActionBar";
import LevelIcon from "./../../../images/level.svg";
import YesNoPrompt from "../../common/YesNoDialog";
import api from "./StoriesService";
import StoriesGrid from "./StoriesGrid";
import StoryCreate from "./StoryCreate";
import StoryEdit from "./StoryEdit";
import _ from "lodash";
import axios from "axios";
class Stories extends Component {
  state = {
    pageSize: 10,
    searchText: "",
    elderName: "",
    sortColumnStory: { path: "title", order: "desc" },
    sortColumnElder: { path: "elderId", order: "desc" },
    elder: null,
    skipPages: 0,
    data: [],
    isLoading: false,
    modalIsDeleteOpen: false,
    modalIsCreateOpen: false,
    modalIsEditOpen: false,
    selectedForDelete: {},
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  token = null;
  componentDidMount = async () => {
    const { elder } = this.props.location;
    const elderName = elder ? elder.name : "";
    await this.setState({ elderName, elder });
    const CancelToken = axios.CancelToken;
    this.token = CancelToken.source();
    this.fetch();
  };

  init = async () => {
    this.setLoading(true);
    this.setState({
      data: [],
      skipPages: 0,
    });
    this.debouncedSearch();
    this.setLoading(false);
  };

  fetch = async () => {
    this.setLoading(true);
    const { searchText, skipPages, sortColumnStory, sortColumnElder } =
      this.state;
    console.log(this.state.elder);
    var newData =
      this.state.elder === undefined
        ? [
            ...(
              await api.Get(searchText, skipPages, sortColumnElder, this.token)
            ).data,
          ]
        : [
            ...(
              await api.GetByElder(
                this.state.elder.id,
                searchText,
                skipPages,
                sortColumnStory,
                this.token
              )
            ).data,
          ];

    var data = [...this.state.data, ...newData];
    this.setState({
      data,
    });
    this.setLoading(false);
  };

  setLoading = (isLoading) => {
    this.setState({ isLoading: isLoading });
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.fetch();
  };

  handleSearch = async (text) => {
    await this.setState({
      data: [],
      searchText: text,
      skipPages: 0,
    });
    this.token.cancel("User search again");
    this.debouncedSearch();
  };

  debouncedSearch = _.debounce(function () {
    this.fetch();
  }, 700);

  handelAdd = () => {
    this.setState({ modalIsCreateOpen: true });
  };

  handelEdit = (data) => {
    this.setState({ modalIsEditOpen: true, selectedForEdit: data });
  };

  handelPages = (data) => {
    this.props.history.push({
      pathname: "/StoryPages",
      search: "?storyId=" + data.id + "&storyName=" + data.title,
    });
  };

  handelDelete = (data) => {
    this.setState({ modalIsDeleteOpen: true, selectedForDelete: data });
  };
  handelFetchMore = async () => {
    const { skipPages } = this.state;
    await this.setState({
      skipPages: skipPages + 1,
    });
    this.fetch();
  };

  handelYesDelete = async (item) => {
    console.log(item);
    await api.Delete(item.id);
    this.setState({ modalIsDeleteOpen: false });
    this.init();
  };

  handelNoDelete = () => {
    this.setState({ modalIsDeleteOpen: false });
  };

  handelCreateSubmit = async (data) => {
    data.ElderId = this.state.elder.id;
    await api.Create(data);
    this.handelCreateCancel();
    this.init();
  };
  handelCreateCancel = () => {
    this.setState({ modalIsCreateOpen: false });
  };

  handelEditSubmit = async (data) => {
    await api.Update(data);
    this.handelEditCancel();
    this.setState({ selectedForEdit: null });
    this.init();
  };

  handelEditCancel = () => {
    this.setState({ modalIsEditOpen: false });
  };

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }

  renderMainLayoutContent() {
    const {
      data,
      isLoading,
      modalIsDeleteOpen,
      modalIsCreateOpen,
      elderName,
      modalIsEditOpen,
      selectedForDelete,
      selectedForEdit,
    } = this.state;

    let title = elderName ? `Stories of ${elderName}` : "All Stories";
    return (
      <div>
        <div>
          <ActionBar
            icon={LevelIcon}
            title={title}
            onAdd={this.handelAdd}
            onSearch={this.handleSearch}
          />
        </div>
        <StoriesGrid
          items={data}
          onEdit={this.handelEdit}
          onDelete={this.handelDelete}
          isLoading={isLoading}
          skipPages={this.state.skipPages}
          pageSize={this.state.pageSize}
          onPages={this.handelPages}
          onFetchMore={this.handelFetchMore}
        />
        <YesNoPrompt
          isOpen={modalIsDeleteOpen}
          title="Are you sure?"
          data={selectedForDelete}
          onPositive={this.handelYesDelete}
          onNegative={this.handelNoDelete}
        />

        <StoryCreate
          key={"Add_" + modalIsCreateOpen}
          isOpen={modalIsCreateOpen}
          onPositive={this.handelCreateSubmit}
          onNegative={this.handelCreateCancel}
          onClose={this.handelCreateCancel}
        />

        <StoryEdit
          key={selectedForEdit && selectedForEdit.id}
          isOpen={modalIsEditOpen}
          data={selectedForEdit}
          onPositive={this.handelEditSubmit}
          onNegative={this.handelEditCancel}
          onClose={this.handelEditCancel}
        />
      </div>
    );
  }
}

export default Stories;
