import React from "react";

const SearchBox = ({ searchIcon, onSearch, hidden }) => {
  return (
    <div
      hidden={hidden}
      className="form-group float-right"
      style={{ marginTop: -5, marginLeft: 15 }}
    >
      <div className="form-control div-search shadow-sm">
        <img alt="icon" src={searchIcon}></img>
        <input
          onChange={(e) => { onSearch(e.currentTarget.value) }}
          style={{ backgroundColor: "transparent", border: "none" }}
          className="search"
        />
      </div>
    </div>
  );
};

export default SearchBox;
