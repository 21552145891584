import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./Input";
import Select from "./Select";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  isValidate() {
    return this.validate() !== null;
  }
  validate() {
    const options = {
      abortEarly: false,
    };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  }

  validateProperty({ name, value }) {
    const obj = { [name]: value };

    const schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  }
  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handelChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderSubmitButton(label) {
    return (
      <button
        disabled={this.validate()}
        style={{ marginTop: 20 }}
        type="submit"
        className="fadeIn fourth center button-gradient-purple"
      >
        {label}
      </button>
    );
  }
  renderInput(name, label, icon, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        type={type}
        title={label}
        onChange={this.handelChange}
        icon={icon}
        value={data[name]}
        error={errors[name]}
      />
    );
  }

  handelSelectChange = (name, value) => {
    this.handelChange({
      currentTarget: { name: name, value: value._id },
    });
  };

  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        label={label}
        onChange={this.handelSelectChange}
        options={options}
        value={data[name]}
        error={errors[name]}
      />
    );
  }
}

export default Form;
