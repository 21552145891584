import React, { Component } from "react";
import MainLayout from "../../../layout/MainLayout";
import ActionBar from "../../../common/ActionBar";
import RootsIcon from "../../../../images/roots.svg";
import NavigationCuredButton from "../../../common/NavigationCuredButton";
import { WordAttributes } from "../../../../domain/Contracts";
import Progressbar from "../../../common/Progressbar";
import Word from "../Word";

import BaseVerbItem from "./BaseVerbItem";
class Verb extends Word {
  state = {
    progressbarIsShow: false,
    selectedIndex: 0,
    isLoading: false,
    items: [
      {
        text: "Imperfective",
        index: 0,
        persons: [
          {
            index: 1,
            text: "First person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveFirstPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveFirstPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveFirstPersonPlural.id,
              },
            ],
          },
          {
            index: 2,
            text: "Second person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveSecondPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveSecondPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveSecondPersonPlural.id,
              },
            ],
          },
          {
            index: 3,
            text: "Third person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveThirdPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveThirdPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.ImperfectiveThirdPersonPlural.id,
              },
            ],
          },
        ],
        disabled: false,
        hasNext: true,
        hasPrevious: false,
      },
      {
        text: "Perfective",
        index: 1,
        persons: [
          {
            index: 1,
            text: "First person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveFirstPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveFirstPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveFirstPersonPlural.id,
              },
            ],
          },
          {
            index: 2,
            text: "Second person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveSecondPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveSecondPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveSecondPersonPlural.id,
              },
            ],
          },
          {
            index: 3,
            text: "Third person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveThirdPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveThirdPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.PerfectiveThirdPersonPlural.id,
              },
            ],
          },
        ],
        disabled: true,
        hasNext: true,
        hasPrevious: true,
      },
      {
        index: 2,
        text: "Future",
        persons: [
          {
            index: 1,
            text: "First person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureFirstPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureFirstPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureFirstPersonPlural.id,
              },
            ],
          },
          {
            index: 2,
            text: "Second person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureSecondPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureSecondPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureSecondPersonPlural.id,
              },
            ],
          },
          {
            index: 3,
            text: "Third person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureThirdPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureThirdPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.FutureThirdPersonPlural.id,
              },
            ],
          },
        ],
        disabled: true,
        hasNext: true,
        hasPrevious: true,
      },
      {
        index: 3,
        text: "Optative",
        persons: [
          {
            index: 1,
            text: "First person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeFirstPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeFirstPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeFirstPersonPlural.id,
              },
            ],
          },
          {
            index: 2,
            text: "Second person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeSecondPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeSecondPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeSecondPersonPlural.id,
              },
            ],
          },
          {
            index: 3,
            text: "Third person",
            subItems: [
              {
                id: "",
                rowVersion: "",
                item: "Singular",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeThirdPersonSingular.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Dual",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeThirdPersonDual.id,
              },
              {
                id: "",
                rowVersion: "",
                item: "Plural",
                text: "",
                oldText: "",
                voice: null,
                changeVoice: false,
                error: "",
                processIsShow: false,
                attribute: WordAttributes.OptativeThirdPersonPlural.id,
              },
            ],
          },
        ],
        disabled: true,
        hasNext: false,
        hasPrevious: true,
      },
    ],
  };

  render() {
    return <MainLayout content={this.renderMainLayoutContent()} />;
  }
  handelWordChange = (word, text) => {
    const items = this.state.items.map((item) => {
      const persons = item.persons.map((person) => {
        const subItems = person.subItems.map((subItem) => {
          if (subItem.attribute === word.attribute) {
            subItem.text = text;
          }
          return subItem;
        });
        person.subItems = subItems;
        return person;
      });
      item.persons = persons;
      return item;
    });
    this.setState({
      items,
    });
  };
  handelChooseVoice = (word, file, isDownload) => {
    const items = this.state.items.map((item) => {
      const persons = item.persons.map((person) => {
        const subItems = person.subItems.map((subItem) => {
          if (subItem.attribute === word.attribute) {
            subItem.voice = URL.createObjectURL(file);
            subItem.fileAudio = file;
            if (!isDownload) {
              subItem.changeVoice = true;
            }
          }
          return subItem;
        });
        person.subItems = subItems;
        return person;
      });
      item.persons = persons;
      return item;
    });
    this.setState({
      items,
    });
  };

  renderMainLayoutContent() {
    return (
      <div>
        <ActionBar icon={RootsIcon} title="Verb" />
        <NavigationCuredButton
          items={this.state.items}
          onClick={this.handelNavigationCuredClick}
        />
        <BaseVerbItem
          onAddExample={this.handelAddExample}
          onEditExample={this.handelEditExample}
          onDeleteExample={this.handelDeleteExample}
          onWordChange={this.handelWordChange}
          onChooseVoice={this.handelChooseVoice}
          item={this.state.items[this.state.selectedIndex]}
          onNext={this.handleNext}
          onPrevious={this.handlePrevious}
        />
        <Progressbar
          isOpen={this.state.progressbarIsShow}
          title="Please wait"
        />
      </div>
    );
  }

  syncWords = async (newSelectedItem) => {
    await this.setState({
      progressbarIsShow: true,
    });
    let hasError = false;
    let currentTab = this.state.items[this.state.selectedIndex];
    const items = await Promise.all(
      this.state.items.map(async (item) => {
        const persons = await Promise.all(
          item.persons.map(async (person) => {
            const subItems = await Promise.all(
              person.subItems.map(async (subItem) => {
                subItem.error = "";
                if (currentTab.index === item.index) {
                  if (
                    subItem.text !== subItem.oldText ||
                    subItem.oldVoive !== subItem.voice
                  ) {
                    subItem.error = "";
                    try {
                      if (subItem.text !== subItem.oldText) {
                        if (subItem.oldText === "") {
                          await this.createWord(subItem);
                        } else {
                          if (subItem.text === "") {
                            await this.deleteWord(subItem);
                          } else if (subItem.oldText !== subItem.text) {
                            await this.updateWord(subItem);
                          }
                        }
                      }

                      if (subItem.changeVoice && subItem.id) {
                        await this.deleteWordAudio(subItem);
                        await this.createWordAudio(subItem);
                        subItem.changeVoice = false;
                      }
                      // if (subItem.id) {
                      //   this.deleteExamples(subItem.id);
                      //   subItem.wordExamples.map(async (example) => {
                      //     await this.createExample(example);
                      //     await this.createExampleAudio(example);
                      //   });
                      // }
                    } catch (error) {
                      hasError = true;
                      subItem.error +=
                        " \n" +
                        (error.response
                          ? error.response.data.Message
                          : error.message);
                    }
                  }
                }
                return subItem;
              })
            );
            person.subItems = subItems;
            return person;
          })
        );
        item.persons = persons;
        return item;
      })
    );
    this.init(this.state.rootId);
    if (!hasError) {
      this.setState({
        selectedIndex: newSelectedItem,
      });
    }
    return !hasError;
  };
}

export default Verb;
