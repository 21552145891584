import React from "react";
import ButtonPrimary from "./ButtonPrimary";

const RootBottomButtons = ({
  onSave,
  onCancel,
  onNext,
  onPrevious,
  hasNext,
  hasPrevious,
}) => {
  return (
    <div className="col  border">
      <div className="row" style={{ padding: 10 }}>
        {/* <div className="col-6">
          <ButtonPrimary text="Save" onClick={onSave} />
          {"  "}
          <ButtonPrimary text="Cancel" onClick={onCancel} />
        </div> */}
        <div className="row">
          <div className="float-right">
            {hasPrevious ? (
              <ButtonPrimary
                style={{ padding: "10px", margin: 10, width: "max-content" }}
                text="Save Previous"
                onClick={onPrevious}
              />
            ) : (
              <ButtonPrimary
                style={{
                  padding: "10px",
                  margin: 10,
                  width: "max-content",
                  visibility: "hidden",
                }}
                text="Save Previous"
                onClick={onPrevious}
              />
            )}

            {hasNext ? (
              <ButtonPrimary
                style={{ padding: "10px", margin: 10, width: "max-content" }}
                text="Save Next"
                onClick={onNext}
              />
            ) : (
              <ButtonPrimary
                style={{
                  padding: "10px",
                  margin: 10,
                  width: "max-content",
                  visibility: "hidden",
                }}
                text="Save Next"
                onClick={onNext}
              />
            )}
            <ButtonPrimary
              style={{ padding: "10px", margin: 10, width: "max-content" }}
              text="Save Back"
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootBottomButtons;
