import React, { Component } from "react";
import _ from "lodash";
import { Waypoint } from "react-waypoint";
class TableBody extends Component {
  renderCell(item, column) {
    if (column.content) {
      return column.content(item);
    }
    return _.get(item, column.path);
  }

  renderTd(item, column) {
    return (
      <React.Fragment key={this.createKey(item, column)}>
        {this.renderCell(item, column)}
      </React.Fragment>
    );
  }

  createKey(item, column) {
    return item.id + (column.path || column.key);
  }

  render() {
    const {
      data,
      columns,
      rowClassName,
      onFetchMore,
      isLoading,
      pageSize,
      skipPages,
    } = this.props;
    let className = rowClassName + " row";
    return (
      <div>
        {data.map((item, index) => (
          <React.Fragment key={item.id}>
            <div key={item.id} className={className}>
              {columns.map((column) => this.renderTd(item, column))}
            </div>
            {index === data.length - pageSize && (
              <Waypoint onEnter={onFetchMore} />
            )}
          </React.Fragment>
        ))}
        {isLoading && (
          <div className="alert alert-light" role="alert">
            <div className="d-flex justify-content-center">
              <button className="btn btn-dark" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TableBody;
