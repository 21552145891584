import BaseService from "../../../services/BaseService";
const endpoint = "/WordExample";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  Get(rootId) {
    return BaseService.Get(endpoint, `rootId=${rootId}`);
  },
  Create(wordId, sekaniExample, englishMeaning) {
    let body = {
      WordId: wordId,
      SekaniExample: sekaniExample,
      EnglishMeaning: englishMeaning,
    };
    return BaseService.CreateMultipart(endpoint, body, multipartHeader);
  },
  Delete(id) {
    return BaseService.Delete(endpoint, id);
  },
  Update(id, rowVersion, WordId, sekaniExample, englishMeaning) {
    let body = {
      Id: id,
      RowVersion: rowVersion,
      WordId: WordId,
      sekaniExample: sekaniExample,
      englishMeaning: englishMeaning,
    };
    return BaseService.UpdateMultipart(endpoint, body, multipartHeader);
  },
};
