import React from "react";
const Card = ({ title, body, bottom, ...rest }) => {
  return (
    <div {...rest}>
      <div style={{ height: 50 }} />
      <div className="card card-item">
        <div className="center">
          <div className="card-item-title center" style={{ marginTop: -12 }}>
            {title}
          </div>
        </div>
        <div className="row card-item-body">{body}</div>
        <div className="center">{bottom}</div>
      </div>
    </div>
  );
};

export default Card;
