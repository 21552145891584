import React, { Component } from "react";
import RootTableBody from "./RootTableBody";
import ButtonPrimary from "../../common/ButtonPrimary";
import TableHeader from "../../common/TableHeader";
import ToggleRootButton from "./ToggleRootButton";
import _ from "lodash";
class RootTable extends Component {
  columns = [
    {
      path: "",
      lable: "Image",
      headerStyle: { width: "10%", textAlign: "center" },
      content: (item) => (
        <div
          className=" "
          style={{
            width: "10%",
          }}
        >
          <img
            className="img-fluid "
            style={{
              borderRadius: "50%",
              background: "#8778C8",
              padding: "5px",
              margin: "5px",
              maxWidth: "86%",
              maxHeight: "86%",
            }}
            hidden={!item.imageUrl}
            src={item.imageUrl}
          />
        </div>
      ),
      key: "",
    },
    {
      path: "rootWord",
      lable: "Root",
      headerStyle: {
        width: "10%",
        textAlign: "left",
      },
      content: (item) => (
        <div className=" " style={{ width: "10%" }}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            className="text-wrap "
          >
            {" "}
            {item.rootWord}
          </div>
        </div>
      ),
      key: "rootWord",
    },
    {
      path: "level.name",
      lable: "Level",
      headerStyle: {
        width: "6%",
        textAlign: "left",
      },
      content: (item) => (
        <div className=" " style={{ width: "6%" }}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            className="text-wrap "
          >
            {" "}
            {item.level.name}
          </div>
        </div>
      ),
      key: "level.name",
    },
    {
      path: "level.number",
      lable: "Lvl n",
      headerStyle: {
        width: "5%",
        textAlign: "left",
      },
      content: (item) => (
        <div className=" " style={{ width: "5%" }}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            className="text-wrap "
          >
            {" "}
            {item.level.number}
          </div>
        </div>
      ),
      key: "level.number",
    },
    {
      path: "category.name",
      lable: "Category",
      headerStyle: {
        width: "10%",
        textAlign: "left",
      },
      content: (item) => (
        <div className="" style={{ width: "10%" }}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            className="text-wrap "
          >
            {item.category.name}
          </div>
        </div>
      ),
      key: "category.name",
    },
    {
      path: "form",
      lable: "Form",
      headerStyle: {
        width: "10%",
        textAlign: "left",
      },
      content: (item) => (
        <div className="" style={{ width: "10%" }}>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            className="text-wrap "
          >
            {item.formName}
          </div>
        </div>
      ),
      key: "form",
    },

    {
      path: "",
      lable: "English Word",
      headerStyle: {
        width: "10%",
        textAlign: "left",
      },
      content: (item) => (
        <div className=" " style={{ width: "10%" }}>
          <div className="row-cols-auto">
            {item.englishWords
              .map((word, index) => {
                if (_.size(word.englishWords) == index) {
                  return this.renderEnglishWordsLast(word.word);
                } else {
                  return this.renderEnglishWords(word.word);
                }
              })
              .reverse()}
          </div>
        </div>
      ),
      key: "englishWord",
    },
    {
      path: "",
      lable: "",
      key: "Details",
      headerStyle: {
        width: "39%",
        textAlign: "center",
      },
      content: (item) => (
        <div
          className="row row-cols-auto col-4"
          style={{ width: "39%", margin: "auto" }}
        >
          <ButtonPrimary
            className="col"
            text="Details"
            onClick={() => this.props.onDetails(item)}
          />

          <ButtonPrimary
            className="col"
            text="Edit"
            onClick={() => this.props.onEdit(item)}
          />

          <ButtonPrimary
            className="col"
            text="Delete"
            onClick={() => this.props.onDelete(item)}
          />
          <ButtonPrimary
            className="col-3"
            text="English Words"
            onClick={() => this.props.onEnglishWords(item)}
          />

          <ButtonPrimary
            className="col"
            text="Topics"
            onClick={() => this.props.onTopics(item)}
          />
          <div className="col">
            <ToggleRootButton
              checked={item.isShowDetails}
              onClick={() => this.props.showDetails(item)}
            />
          </div>
        </div>
      ),
    },
  ];
  renderEnglishWords(word) {
    return <div style={{ height: "fit-content" }}>{word},</div>;
  }
  renderEnglishWordsLast(word) {
    return <div style={{ height: "fit-content" }}>{word}</div>;
  }
  onSort(sortColumn) {
    this.props.onSort(sortColumn);
  }
  state = {};
  render() {
    const { items, sortColumn, pageSize, onSort, onFetchMore, isLoading } =
      this.props;

    return (
      <div className="table-outer" style={{ width: "94%", marginLeft: "2%" }}>
        <div>
          <div className="table-header-outer  ">
            <div className="table-inner table-header-inner">
              <TableHeader
                sortColumn={sortColumn}
                columns={this.columns}
                onSort={onSort}
              />
            </div>
          </div>

          <div className="  ">
            <div className="table-inner">
              <RootTableBody
                rowClassName="table-item "
                columns={this.columns}
                data={items}
                pageSize={pageSize}
                isLoading={isLoading}
                onFetchMore={onFetchMore}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RootTable;
