import * as Sentry from "@sentry/browser";

function init() {
  Sentry.init({
    release: "1.0.0",
    environment: "development-test",
    dsn:
      "https://38cba31d53f24ef1abd902832d57236c@o384955.ingest.sentry.io/5216990",
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        dom: false,
        xhr: false,
      }),
    ],
  });
}

function log(error) {
  Sentry.captureException(error);
}

export default {
  init,
  log,
};
