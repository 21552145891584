import React, { Component } from "react";
import WordApi from "./WordService";
import WordAudio from "./WordAudioService";
import WordExampleAudioApi from "./WordExampleAudioService";
import ExampleServiceApi from "./ExampleService";
import queryString from "query-string";
import _ from "lodash";
class Word extends Component {
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    let rootId = params.rootId;
    this.setState({
      rootId,
    });
    this.init(rootId);
  }
  setLoading = async (flag) => {
    this.setState({
      progressbarIsShow: flag,
    });
  };
  init = async (rootId) => {
    this.setLoading(true);
    var data = await WordApi.Get(rootId);
    this.initWords(data.data);
    this.setLoading(false);
  };

  initWords = async (remoteWords) => {
    const items = this.state.items.map((item) => {
      const persons = item.persons.map((person) => {
        const subItems = person.subItems.map((subItem) => {
          remoteWords.map(async (word) => {
            if (subItem.attribute === word.attribute) {
              subItem.id = word.id;
              subItem.rootId = word.rootId;
              subItem.rowVersion = word.rowVersion;
              subItem.text = word.text;
              subItem.oldText = word.text;
              if (!word.wordExamples) {
                subItem.wordExamples = [];
              } else {
                subItem.wordExamples = word.wordExamples;
              }
              subItem.audioUrl = word.audioUrl;
            }
          });
          return subItem;
        });
        person.subItems = subItems;
        return person;
      });
      item.persons = persons;
      return item;
    });
    this.setState({
      items,
    });
  };

  handelNavigationCuredClick = (item) => {
    // this.syncWords();
    // this.setItemsEnable(item);
    // this.setState({ selectedIndex: item.index })
  };

  handleNext = async (item) => {
    var isSuccess = await this.syncWords(item.index + 1);
    if (isSuccess) {
      const found = await this.state.items.find(
        (i) => i.index === item.index + 1
      );
      this.setItemsEnable(found);
    }

    // this.setState({ selectedIndex: item.index + 1 })
  };
  handlePrevious = async (item) => {
    var isSuccess = await this.syncWords(item.index - 1);
    if (isSuccess) {
      const found = await this.state.items.find(
        (i) => i.index === item.index - 1
      );
      this.setItemsEnable(found);
    }

    // this.setState({ selectedIndex: item.index - 1 })
  };

  handelSave = async (item) => {
    var isSuccess = await this.syncWords(item.index);
    if (isSuccess) {
      this.props.history.goBack();
    }
  };
  syncWords = () => {
    let currentTab = this.state.items[this.state.selectedIndex];
    currentTab.persons.map((person) => {
      person.subItems.map((subItem) => {
        if (subItem.text !== subItem.oldText) {
          if (subItem.oldText === "") {
            this.createWord(subItem);
          } else {
            if (subItem.text === "") {
              this.deleteWord(subItem);
              this.deleteWordAudio(subItem);
              this.createWordAudio(subItem);
            } else if (subItem.oldText !== subItem.text) {
              this.updateWord(subItem);
              this.deleteWordAudio(subItem);
            }
          }
        }
      });
    });
    this.init(this.state.rootId);
  };

  createWordAudio = (word) => {
    return WordAudio.Create(this.state.rootId, word.id, word.voice);
  };

  deleteWordAudio = (word) => {
    return WordAudio.Delete(this.state.rootId, word.id);
  };

  createWord = (word) => {
    return WordApi.Create(
      this.state.rootId,
      word.text,
      word.fileAudio,
      word.attribute
    );
  };

  updateWord = (word) => {
    return WordApi.Update(
      this.state.rootId,
      word.id,
      word.rowVersion,
      word.text,
      word.attribute
    );
  };

  deleteWord = (word) => {
    return WordApi.Delete(this.state.rootId, word.id);
  };

  setItemsEnable = async (clickedItem) => {
    if (clickedItem === null) return;
    const items = await this.state.items.map((item) => {
      item.disabled = true;
      if (clickedItem.index === item.index) {
        item.disabled = false;
      }
      return item;
    });
    this.setState({
      items,
    });
  };

  handelAddExample = async (word, example) => {
    var exampleId = await this.createExample(example, word.id);
    example.id = exampleId.data;
    if (example.fileVoice) {
      this.createExampleAudio(example);
    }
    const items = this.state.items.map((item) => {
      const persons = item.persons.map((person) => {
        const subItems = person.subItems.map((subItem) => {
          if (subItem.attribute === word.attribute) {
            example.index = subItem.wordExamples.length;
            subItem.wordExamples = [...subItem.wordExamples, example];
          }
          return subItem;
        });
        person.subItems = subItems;
        return person;
      });
      item.persons = persons;
      return item;
    });
    this.init(this.state.rootId);
  };

  handelEditExample = (word, example) => {
    this.editExample(example);
    this.deleteExampleAudio(example);
    if (example.fileVoice) {
      this.createExampleAudio(example);
    }
    const items = this.state.items.map((item) => {
      const persons = item.persons.map((person) => {
        const subItems = person.subItems.map((subItem) => {
          if (subItem.attribute === word.attribute) {
            subItem.wordExamples[example.index] = example;
          }
          return subItem;
        });
        person.subItems = subItems;
        return person;
      });
      item.persons = persons;
      return item;
    });
    this.init(this.state.rootId);
  };

  handelDeleteExample = (word, example) => {
    this.deleteExamples(example);
    const items = this.state.items.map((item) => {
      const persons = item.persons.map((person) => {
        const subItems = person.subItems.map((subItem) => {
          if (subItem.attribute === word.attribute) {
            _.remove(subItem.wordExamples, function (e) {
              return e.index === example.index;
            });
          }
          return subItem;
        });
        person.subItems = subItems;
        return person;
      });
      item.persons = persons;
      return item;
    });
    this.setState({
      items,
    });
  };
  createExample = (example, wordId) => {
    return ExampleServiceApi.Create(
      wordId,
      example.sekaniExample,
      example.englishMeaning
    );
  };

  editExample = (example) => {
    ExampleServiceApi.Update(
      example.id,
      example.rowVersion,
      example.wordId,
      example.sekaniExample,
      example.englishMeaning
    );
  };

  deleteExampleAudio = (example) => {
    WordExampleAudioApi.Delete(example.id);
  };

  createExampleAudio = (example) => {
    WordExampleAudioApi.Create(example.id, example.voice);
  };

  deleteExamples = (example) => {
    ExampleServiceApi.Delete(example.id);
  };
}

export default Word;
