import React from "react";
import PlayIcon from "../../images/play.svg";
import StopIcon from "../../images/stop.svg";
import SpeakerDisableIcon from "../../images/speaker_disable.svg";
import Spinner from "./Spinner";

class MusicDownload extends React.Component {
  state = {
    play: false,
  };
  audio = new Audio();

  componentDidMount = async () => {
    const { url } = this.props;
    if (url) {
      await this.setState({ isEnable: false, isLoading: true });
      let file = await fetch(url).then((r) => r.blob());
      await this.setState({ file, isEnable: false, isLoading: false });
    } else {
      await this.setState({ isEnable: true });
    }
    this.audio.addEventListener("ended", () => this.setState({ play: false }));
  };

  downloadFile = async () => {};

  componentWillUnmount() {
    this.audio.removeEventListener("ended", () =>
      this.setState({ play: false })
    );
  }

  togglePlay = () => {
    const { file } = this.state;
    if (file !== null) this.audio.src = URL.createObjectURL(file);
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
    });
  };

  render() {
    const { isEnable } = this.state;
    return (
      <div>
        {!this.state.isLoading ? (
          <img
            onClick={this.togglePlay}
            src={
              isEnable
                ? SpeakerDisableIcon
                : this.state.play
                ? StopIcon
                : PlayIcon
            }
            alt="Icon"
            className="nav-button-icon"
          ></img>
        ) : (
          <Spinner
            size="1rem"
            margin="3px"
            className="word-input-in-div"
            hidden={this.state.isLoading}
          />
        )}
      </div>
    );
  }
}

export default MusicDownload;
