import React, { Component } from "react";
import Modal from "react-modal";
import ModalInput from "./ModalInput";
import Select from "./Select";
import ModalTextArea from "./ModalTextArea";
import Image from "../common/Image";
import Close from "../../images/modal_close.svg";
import Joi from "joi-browser";
import ImageRounded from "./ImageRounded";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
  },
};
class BaseModal extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate() {
    const options = {
      abortEarly: true,
    };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  }

  isValidate() {
    return this.validate() !== null;
  }

  validateProperty({ name, value }) {
    const obj = { [name]: value };

    const schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handelChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handelSelectChange = (name, value) => {
    this.handelChange({
      currentTarget: { name: name, value: value._id },
    });
  };

  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        label={label}
        onChange={this.handelSelectChange}
        options={options}
        value={data[name]}
        error={errors[name]}
      />
    );
  }

  renderModal(title, onClose, isOpen, body) {
    return (
      <Modal ariaHideApp={false} style={customStyles} isOpen={isOpen}>
        <div className="modal-background-create">
          <img
            src={Close}
            className="modal-close"
            onClick={onClose}
            alt="Close Icon"
          />
          <div className="modal-text-title center">{title}</div>
          {body}
        </div>
      </Modal>
    );
  }
  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <ModalInput
        name={name}
        type={type}
        title={label}
        onChange={this.handelChange}
        value={data[name]}
        error={errors[name]}
      />
    );
  }

  renderImage(name, image, noImageIcon, error, width) {
    return (
      <Image
        name={name}
        image={image}
        noImageIcon={noImageIcon}
        width={width}
        error={error}
      />
    );
  }

  renderImageRounded(name, image, noImageIcon, error, width) {
    return (
      <ImageRounded
        name={name}
        image={image}
        noImageIcon={noImageIcon}
        width={width}
        error={error}
      />
    );
  }

  renderTextArea(name, label) {
    const { data, errors } = this.state;
    return (
      <ModalTextArea
        name={name}
        title={label}
        onChange={this.handelChange}
        value={data[name]}
        error={errors[name]}
      />
    );
  }
}

export default BaseModal;
