import BaseService from "../../../services/BaseService";
const endpoint = "/Category";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  GetAll(order) {
    return BaseService.GetAll(endpoint, order);
  },
  GetList(SearchText, SkipPages, order, token) {
    return BaseService.Get(endpoint, SearchText, SkipPages, order, 30, token);
  },
  Create(obj) {
    return BaseService.Create(endpoint, obj);
  },
  Delete(id) {
    return BaseService.Delete(endpoint, id, multipartHeader);
  },
  Update(obj) {
    return BaseService.Update(endpoint, obj);
  },
};
