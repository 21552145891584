import BaseService from "../../../services/BaseService";
const endpoint = "/Word";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  Get(rootId, order) {
    return BaseService.GetAllQueryString(
      `${endpoint}`,
      `?rootId=${rootId}`,
      order
    );
  },
  Create(rootId, text, audioFile, attribute) {
    let body = {
      RootId: rootId,
      Text: text,
      audioFile: audioFile,
      Attribute: attribute,
    };
    return BaseService.CreateMultipart(endpoint, body, multipartHeader);
  },
  Delete(rootId, id) {
    return BaseService.Delete(endpoint + `/${rootId}`, id);
  },
  Update(rootId, id, rowVersion, text, attribute, audioFile) {
    let body = {
      Id: id,
      RootId: rootId,
      Text: text,
      RowVersion: rowVersion,
      Attribute: attribute,
      audioFile: audioFile,
    };
    return BaseService.UpdateMultipart(endpoint, body, multipartHeader);
  },
};
