import React, { Component } from "react";
import AddIcon from "../../../images/add.svg";
import ButtonPrimary from "../../common/ButtonPrimary";
import ExampleEdit from "./ExampleEdit";
import ExampleCreate from "./ExampleCreate";
import Music from "../../common/Music";
import MusicDownload from "../../common/MusicDownload";
class Examples extends Component {
  state = {
    modalIsCreateOpen: false,
    selectedForEdit: null,
    modalIsEditOpen: false,
  };
  render() {
    const { examples, word } = this.props;
    const { modalIsCreateOpen, selectedForEdit, modalIsEditOpen } = this.state;

    return (
      <React.Fragment>
        Examples
        <img
          hidden={!word.id}
          src={AddIcon}
          onClick={this.handelAdd}
          alt="Icon"
          className="nav-button-icon "
        ></img>
        {examples &&
          examples.map((example, index) => this.renderSample(example, index))}
        <ExampleCreate
          key={"Add_" + modalIsCreateOpen}
          isOpen={modalIsCreateOpen}
          onPositive={this.handelCreateSubmit}
          onNegative={this.handelCreateCancel}
          onClose={this.handelCreateCancel}
        />
        <ExampleEdit
          key={selectedForEdit && selectedForEdit.id}
          isOpen={modalIsEditOpen}
          data={selectedForEdit}
          onPositive={this.handelEditSubmit}
          onNegative={this.handelEditCancel}
          onClose={this.handelEditCancel}
        />
      </React.Fragment>
    );
  }
  handelAdd = () => {
    this.setState({ modalIsCreateOpen: true });
  };

  handelEdit = (example) => {
    this.setState({ modalIsEditOpen: true, selectedForEdit: example });
  };

  handelCreateSubmit = async (example) => {
    const { word, onExampleCreate } = this.props;
    this.handelCreateCancel();
    onExampleCreate(word, example);
  };
  handelCreateCancel = () => {
    this.setState({ modalIsCreateOpen: false, selectedForEdit: null });
  };

  handelEditSubmit = async (example, index) => {
    const { word, onExampleEdit } = this.props;
    this.handelEditCancel();
    example.index = index;
    onExampleEdit(word, example);
  };

  HandelExampleDelete = async (example, index) => {
    const { word, onExampleDelete } = this.props;
    example.index = index;
    onExampleDelete(word, example);
  };

  handelEditCancel = () => {
    this.setState({ modalIsEditOpen: false, selectedForEdit: null });
  };

  renderSample(example, index) {
    example.index = index;
    return (
      <div className="row" key={index} style={{ padding: 10 }}>
        <div className="col-10 ">{example.sekaniExample}</div>

        {example.fileVoice ? (
          <Music
            isEnable={example.fileVoice === null}
            audio={example.fileVoice}
          />
        ) : (
          example.audioFileName && <MusicDownload url={example.audioFileName} />
        )}

        <div className="col-12 ">{example.englishMeaning}</div>
        <ButtonPrimary
          onClick={() => this.handelEdit(example)}
          className="col-12 "
          text="Edit"
        />
        <ButtonPrimary
          onClick={() => this.HandelExampleDelete(example)}
          className="col-12 "
          text="Delete"
        />
      </div>
    );
  }
}

export default Examples;
