import React from "react";

import { NavLink } from "react-router-dom";
import IconButton from "./../common/IconButton";

import UserIcon from "../../images/user.svg";
import SettingIcon from "../../images/setting.svg";
import PowerOffIcon from "../../images/power_off.svg";
import SekaniLogo from "./../../images/sekani_logo.svg";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light header-background justify-content-between">
      <NavLink className="navbar-brand" to="\" style={{ marginLeft: 15 }}>
        <img
          src={SekaniLogo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt=""
        />
        <label className="navbar-title">Sekani Web Portal</label>
      </NavLink>
      <div style={{ marginRight: 15 }}>
        {/* <IconButton
          className="my-2 my-sm-0"
          icon={UserIcon}
          label="user"
          to="/myProfile"
        /> */}
        {/* <IconButton
          className="my-2 my-sm-0"
          icon={SettingIcon}
          label="setting"
          to="/setting"
        /> */}

        <IconButton
          className="my-2 my-sm-0"
          icon={PowerOffIcon}
          label="logout"
          to="/logout"
        />
      </div>
    </nav>
  );
};

export default Header;
