import React, { Component } from "react";
import LockIcon from "../../images/lock.svg";
import EyeIcon from "../../images/eye.svg";
import EyeIconDisable from "../../images/eye_disable.svg";
class HLabelPassword extends Component {
  state = {
    visible: false,
  };
  render() {
    const { title, value, name, onChange, error } = this.props;
    return (
      <React.Fragment>
        <div className="input-title ">{title}</div>
        <div className="form-control div-input shadow-sm">
          <img src={LockIcon} alt="lock" className="icon-input-left"></img>
          <input
            type={this.state.visible ? "text" : "password"}
            className="input-in-div"
            value={value}
            name={name}
            onChange={onChange}
          />
          <img
            src={this.state.visible ? EyeIcon : EyeIconDisable}
            alt="envelope"
            className="icon-input-right clickable"
            style={{ padding: 4 }}
            onClick={() => {
              this.handleOnClickVisible();
            }}
            onKeyUp={() => {
              this.handleOnClickVisible();
            }}
          ></img>
        </div>
        {error && (
          <div className="input-error ">
            <label>{error}</label>
          </div>
        )}
      </React.Fragment>
    );
  }

  handleOnClickVisible() {
    this.setState({ visible: !this.state.visible });
  }
}

export default HLabelPassword;
