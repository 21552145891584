import React from "react";

import AddIcon from "./../../images/add.svg";
import SearchIcon from "./../../images/search.svg";
import SearchBox from "./SearchBox";

const ActionBar = ({ icon, title, onAdd, onSearch, addDisabled = false }) => {
  return (
    <div style={{ margin: 5 }}>
      <div
        className="row margin-zero card-list-title"
        style={{ paddingTop: 15 }}
      >
        <div className="col  ">
          <img
            src={icon}
            alt="Icon"
            className="icon-input-left nav-button-icon "
          ></img>
          <label className="nav-button-label">{title}</label>
        </div>
        <div className="col">
          <SearchBox
            hidden={typeof onSearch !== "function"}
            className="float-right"
            searchIcon={SearchIcon}
            onSearch={onSearch}
          />
          <img
            hidden={typeof (onAdd !== "function") && addDisabled}
            src={AddIcon}
            alt="Icon"
            className="float-right "
            onClick={onAdd}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
