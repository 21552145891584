import BaseService from "../../../services/BaseService";
const endpoint = "/Root";
const multipartHeader = {
  headers: { "content-type": "multipart/form-data", accept: "*/*" },
};
export default {
  GetAll(order) {
    return BaseService.GetAll(endpoint, order);
  },
  GetList(SearchText, SkipPages, order, token) {
    return BaseService.Get(
      endpoint + "/Get",
      SearchText,
      SkipPages,
      order,
      30,
      token
    );
  },
  Create(obj) {
    return BaseService.CreateMultipart(
      endpoint + "/Create",
      obj,
      multipartHeader
    );
  },
  SetEnglishWord(object) {
    const formData = new FormData();

    object.EnglishWords.forEach((item) => {
      formData.append("EnglishWords[]", item);
    });

    formData.append("RootId", object.RootId);

    return BaseService.Create(
      endpoint + "/SetEnglishWord",
      formData,
      multipartHeader
    );
  },
  SetTopics(object) {
    const formData = new FormData();

    object.Topics.forEach((item) => {
      formData.append("EnglishWords[]", item);
    });

    formData.append("RootId", object.RootId);

    return BaseService.Create(
      endpoint + "/SetTopics",
      formData,
      multipartHeader
    );
  },
  Delete(id) {
    return BaseService.Delete(endpoint + "/Delete", id);
  },
  Update(obj) {
    return BaseService.UpdateMultipart(endpoint + "/Update", obj);
  },
};
