import React from "react";

const ThreePartLayout = ({ header, navigationBar, content }) => {
  return (
    <div className="h-100">
      <div className="row margin-zero">
        <div className="col padding-zero ">{header}</div>
      </div>
      <div className="row margin-zero" style={{ height: "89.8%" }}>
        <div className="col-xl-2 col-lg-1 col-md-12 col-sm-12 padding-zero ">
          {navigationBar}
        </div>
        <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 padding-zero">
          {content}
        </div>
      </div>
    </div>
  );
};

export default ThreePartLayout;
